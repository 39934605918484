import { PropsWithChildren } from 'react';
import DorianWhiteSimpleLogo from '../../../../icons/DorianWhiteSimpleLogo.svg';
import cs from './StreamHeader.module.css';
import { StreamHeaderTop } from './StreamHeaderTop/StreamHeaderTop';

type StreamHeaderProps = {
  // handleChatIconClick?: () => void,
  // isNewChatMessage?: boolean,
  isStreamExist?: boolean,
} & PropsWithChildren;

export function StreamHeader(props: StreamHeaderProps) {
  const {
    children,
    isStreamExist,
    // isNewChatMessage,
    // handleChatIconClick,
  } = props;

  // const showDownloadPopup = useDownloadPopup();

  return (
    <div className={cs.container}>
      <div className={cs.background} />
      <div className={cs.aboveBackgroundWrapper}>
        <StreamHeaderTop
          isStreamExist={isStreamExist}
        />
        {children}
        <div className={cs.logoContainer}>
          <img src={DorianWhiteSimpleLogo} alt="Dorian Logo" />
        </div>
      </div>
    </div>
  );
}
