import axios from 'axios';
import { ScheduledEventResponseData } from '../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventResponseData';
import { RuntimeEnv, getRuntimeEnv } from '../getRuntimeEnv';
import { getAuthKeyPairFromLocalStorage } from '../providers/AuthProvider';

const apiServerUrlByEnvLabel = {
  local: 'http://localhost', // TODO: which port
  unstable1: 'http://ec2-3-141-106-2.us-east-2.compute.amazonaws.com:3003',
  unstable2: 'http://ec2-18-217-33-72.us-east-2.compute.amazonaws.com:3003',
  staging: 'https://api.dev.test.4dorian.net',
  tactical: 'http://ec2-3-144-164-157.us-east-2.compute.amazonaws.com:3003',
  prod: 'https://api.live.4dorian.net',
};

const runtimeEnv = getRuntimeEnv();
const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';
console.log('api', envLabel);

export const apiServerUrl = apiServerUrlByEnvLabel[envLabel];

export const apiServer = axios.create({
  baseURL: `${apiServerUrl}/v2`,
});

export async function getScheduledStreamWeb(scheduledStreamId: number) {
  const authKeyPair = getAuthKeyPairFromLocalStorage();
  const response = await apiServer.post<ScheduledEventResponseData>('/getScheduledEventWeb', {
    ...authKeyPair,
    eventId: scheduledStreamId,
  });
  return response.data;
}
