import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  ScheduledEventStatus,
} from '../../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventResponseData';
import { useFetchStreamStatusByInterval } from '../../hooks/useFetchStreamStatusByInterval';
import { ActiveStreamPageV2 } from '../../pages/ActiveStreamPage/ActiveStreamPageV2';
import { fetchMultiplayerStatus } from '../../pages/ActiveStreamPage/fetchMultiplayerStatus';
import { isDebugLogForFeaturedStreams } from '../../pages/FeaturedStreamPage/FeaturedStreamPage';
import { StreamPage, StreamPageProps } from '../../pages/StreamPage/StreamPage';
import { useGlobalStateContext } from '../../providers/GlobalStateProvider';
import { useEpisodeInfo } from '../../services/s3EngineObjectsCache';
import { getCoverUrl } from '../../utils/getCoverUrl';

enum ViewModeSate {
  Initial = 'initial',
  Cover = 'Cover',
  Live = 'live',
}

const getEventTimeTitle = (startTime: string | undefined) => {
  if (startTime) {
    const startDate = parseISO(startTime);
    return format(startDate, 'MMMM do, H:mmaaa O');
  }
  return '';
};

type StreamManagerProps = {
  runningStreamIdFromProps?: number | null,
  scheduledStreamIdFromProps?: number | null,
  avatarUrlFromProps?: string,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  isFeaturedViewFromProps?: boolean;
  refreshFeaturedStreamView?: () => void;
  onCancelStreamCallback?: (isCanceled: boolean) => void;
  onStartStreamCallback?: (eventId: number, sessionId: number) => void;
}

export function StreamManager(props: StreamManagerProps) {
  const {
    runningStreamIdFromProps, scheduledStreamIdFromProps, setIsLoading, isFeaturedViewFromProps,
    refreshFeaturedStreamView, onStartStreamCallback, onCancelStreamCallback, avatarUrlFromProps,
  } = props;

  const [viewModeSate, setModeState] = useState<ViewModeSate>(ViewModeSate.Initial);
  const [episodeId, setEpisodeId] = useState('');
  const [isStreamExist, setIsStreamExist] = useState<boolean | undefined>();
  const [isMultiplayer, setIsMultiplayer] = useState(false);

  const [searchParams] = useSearchParams();
  const globalState = useGlobalStateContext();

  // const multiplayerIdForced = runningStreamIdFromProps ?? Number(searchParams.get('multiplayerId'));
  const scheduledStreamId = scheduledStreamIdFromProps ?? Number(searchParams.get('scheduledStreamId'));
  const isFeaturedView = isFeaturedViewFromProps ?? Boolean(searchParams.get('isFeaturedView'));

  let { scheduledStreamData, isCanceledStream } = useFetchStreamStatusByInterval(
    scheduledStreamId,
    runningStreamIdFromProps,
    onCancelStreamCallback,
    onStartStreamCallback,
    undefined,
    isFeaturedView,
  );

  if (runningStreamIdFromProps) {
    scheduledStreamData = undefined;
    isCanceledStream = false;
  }
  const multiplayerId = runningStreamIdFromProps
    || Number(searchParams.get('multiplayerId')) || scheduledStreamData?.multiplayerId;

  const setEpisodeIdWrapper = useCallback((episodeId: string, sourceInfo?: string) => {
    setEpisodeId(episodeId);
    if (isDebugLogForFeaturedStreams) {
      console.log('######## setEpisodeId episodeId', episodeId, sourceInfo);
    }
  }, []);

  const setIsLoadingWrapper = useCallback((isLoading: boolean) => {
    if (!setIsLoading) {
      return;
    }
    setIsLoading(isLoading);
  }, [setIsLoading]);

  useEffect(() => {
    const appId = searchParams.get('appName') ?? 'dorian';
    globalState?.setAppId(appId);
  }, [searchParams, globalState]);

  useEffect(() => {
    const viewMode = searchParams.get('view');
    switch (viewMode) {
      case 'cover':
        setModeState(ViewModeSate.Cover);
        break;
      case 'live':
        setModeState(ViewModeSate.Live);
        break;
      default:
        setModeState(ViewModeSate.Live);
    }
  }, [searchParams]);

  useEffect(() => {
    if (scheduledStreamData) {
      if (isDebugLogForFeaturedStreams) {
        console.log('%c scheduledStreamData CHANGED', 'color: purple', scheduledStreamData.episodeId);
      }
      setEpisodeId(scheduledStreamData.episodeId);
    }
  }, [scheduledStreamData]);

  useEffect(() => {
    if (multiplayerId) {
      setIsLoadingWrapper(true);
      fetchMultiplayerStatus(multiplayerId).then((newMultiplayerStatus) => {
        if (isDebugLogForFeaturedStreams) {
          console.log('%c \t\tfetchMultiplayerStatus', 'color: violet; background-color: blue', newMultiplayerStatus);
        }
        if (!newMultiplayerStatus.episodeId) {
          if (isFeaturedView && refreshFeaturedStreamView) {
            setIsLoadingWrapper(true);
            setTimeout(() => {
              refreshFeaturedStreamView();
            }, 30000);
          }
          return;
        }
        setEpisodeIdWrapper(newMultiplayerStatus.episodeId, 'Stream manager');
        setIsMultiplayer(Boolean(newMultiplayerStatus.episodeId));
        setIsStreamExist(Boolean(newMultiplayerStatus.episodeId));
        setIsLoadingWrapper(false);
      });
    }
  }, [
    multiplayerId, setIsLoadingWrapper, setEpisodeIdWrapper,
    refreshFeaturedStreamView, isFeaturedView, setIsLoading,
  ]);

  const episodeInfo = useEpisodeInfo(episodeId);

  const isLive = viewModeSate === ViewModeSate.Live;

  const eventTimeTitle = getEventTimeTitle(scheduledStreamData?.startTime);
  const isNotStarted = scheduledStreamData?.status === ScheduledEventStatus.NotStarted;
  const isInProgress = (!isNotStarted && Boolean(multiplayerId)) || isMultiplayer;
  const isShowLive = isLive && isInProgress;
  const isStreamFinished = !isMultiplayer && !isNotStarted;

  const showEventTimeTitle = isStreamFinished || isCanceledStream ? 'Stream Has Ended' : eventTimeTitle;
  const eventTitle = scheduledStreamData?.eventTitle ?? episodeInfo?.title;

  const streamPageProps: StreamPageProps = {
    coverImageURL: getCoverUrl(episodeInfo?.bookCover),
    eventTimeTitle: isInProgress ? '' : showEventTimeTitle,
    eventTitle: eventTitle ?? '',
    hostUsername: scheduledStreamData?.hostUsername ?? '',
    hostAvatarUrl: avatarUrlFromProps ?? scheduledStreamData?.avatarUrl ?? '',
    isFinished: isStreamFinished,
    startTime: scheduledStreamData?.startTime,
    episodeId,
  };

  const debugLog = () => {
    console.log('#####StreamManager####');
    console.log('\tisLive', isLive);
    console.log('\tisNotStarted', isNotStarted);
    console.log('\tisInProgress', isInProgress);
    console.log('\tisShowLive', isShowLive);
    console.log('\tisStreamFinished', isStreamFinished);
    console.log('\tisMultiplayer', isMultiplayer);
    console.log('\t<<<<<->>>>');
    console.log('\t\tscheduledStreamData', scheduledStreamData);
    console.log('\t\tepisodeInfo', episodeInfo);
    console.log('\t\tmultiplayerId', multiplayerId);
    console.log('\t\tscheduledStreamId', scheduledStreamId);
    console.log('\t->>>>');
    console.log('######################');
  };
  if (isDebugLogForFeaturedStreams) {
    debugLog();
  }

  return (
    <span>
      {!isShowLive && (
        <StreamPage
          {...streamPageProps}
          isFeaturedView={isFeaturedView}
          refreshFeaturedStreamView={refreshFeaturedStreamView}
        />
      )}
      {isShowLive && (
      <ActiveStreamPageV2
        multiplayerId={multiplayerId}
        eventTitle={eventTitle}
        isFeaturedView={isFeaturedView}
        refreshFeaturedStreamView={refreshFeaturedStreamView}
        episodeId={episodeId}
        setEpisodeId={setEpisodeIdWrapper}
        isStreamExist={isStreamExist}
        setIsStreamExist={setIsStreamExist}
      />
      )}
    </span>
  );
}
