import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { StreamManager } from '../../components/StreamManager/StreamManager';
import { getSizeConfig } from '../../dorian-shared/getSizeConfig';
import { useAuthKeyPair } from '../../providers/AuthProvider';
import { useGlobalStateContext } from '../../providers/GlobalStateProvider';
import { useMultiplayerContext } from '../../providers/MultiPlayerProvider';
import { ScheduledStream } from '../ScheduledStreamPage/types/ScheduledStream';
import { useStreamPageStyles } from '../StreamPage/StreamPageStyles';
import {
  SelectedStream, SimpleSelectedStream, convertStreamDataToSimpleStream,
  getActiveStream, isStreamDataTheSame, shouldSwitchStreamByAudienceFactor,
} from './featuredStreamUtils';
import { Preloader } from './Preloader/Preloader';
import { FeaturedStream } from './types/FeaturedStream';

export const isDebugLogForFeaturedStreams = false;

const streamRefreshTime = 60 * 1000; // 60 sec - how often we check if should show other stream or scheduled stream

export function FeaturedStreamPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [scheduledStreamId, setScheduledStreamId] = useState<number | null | undefined>(null);
  const [runningStreamId, setRunningStreamId] = useState<number | null | undefined>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();
  const selectedStreamDataRef = useRef<SimpleSelectedStream | null>(null);
  const lastStreamChangeTime = useRef<number | null>(null);
  const refreshTimeoutRef = useRef<NodeJS.Timeout>();

  const [searchParams] = useSearchParams();
  const bookStyle = searchParams.get('appName');

  const globalContext = useGlobalStateContext();

  const streamPageStyles = useStreamPageStyles();

  const authKeyPair = useAuthKeyPair();

  const multiplayerService = useMultiplayerContext();

  const sendDisconnectEvent = useCallback(() => {
    if (!runningStreamId) {
      return;
    }

    multiplayerService.leaveWeb(runningStreamId, authKeyPair);
    multiplayerService.disconnect();
  }, [authKeyPair, runningStreamId, multiplayerService]);

  const clearState = useCallback(() => {
    if (isDebugLogForFeaturedStreams) {
      console.log('%c clearState', 'color: red; background-color: black');
    }
    selectedStreamDataRef.current = null;
    setRunningStreamId(null);
    setScheduledStreamId(null);
    setAvatarUrl(undefined);
    sendDisconnectEvent();
  }, [sendDisconnectEvent]);

  const selectStream = useCallback((stream: SimpleSelectedStream) => {
    if (isDebugLogForFeaturedStreams) {
      console.log('%c selectStream', 'color: violet; font-weight: bold', stream);
    }
    clearState();
    const { eventId, sessionId, avatarUrl } = stream;

    if (eventId) {
      setScheduledStreamId(eventId);
      setAvatarUrl(avatarUrl);
    }

    if (sessionId) {
      setRunningStreamId(sessionId);
    }

    selectedStreamDataRef.current = stream;
    lastStreamChangeTime.current = new Date().getTime();
  }, [setRunningStreamId, setScheduledStreamId, clearState]);

  const switchStream = useCallback((streamData: SimpleSelectedStream, source = '') => {
    if (isDebugLogForFeaturedStreams) {
      console.log(
        '%c $$$$$$ switchStream $$$$$$$',
        'color: darkblue; background-color: pink; font-weight: bold',
        streamData,
        source,
      );
    }
    setIsLoading(true);
    selectStream(streamData);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, [selectStream]);

  const transitionToStream = useCallback((toTransitionStreamData: SimpleSelectedStream | null, isForced = false) => {
    const selectedStreamData = selectedStreamDataRef.current;
    if (isDebugLogForFeaturedStreams) {
      console.log('transitionToStream selectedStreamData:', selectedStreamData);
      console.log('transitionToStream toTransitionStreamData:', toTransitionStreamData);
    }
    // if there is nothing to transition to - stop
    if (!toTransitionStreamData) {
      return;
    }

    // if forced from the outside
    if (isForced) {
      switchStream(toTransitionStreamData, '[1] isForced');
      return;
    }

    // if there is no stream at all
    if (!selectedStreamData) {
      switchStream(toTransitionStreamData, '[2] !selectedStreamData');
      return;
    }

    // if it is the same stream - do nothing
    const isTheSameStream = isStreamDataTheSame(selectedStreamData, toTransitionStreamData);
    if (isTheSameStream) {
      // update time in case it changed
      if (toTransitionStreamData.startDate !== undefined && toTransitionStreamData.startDate !== null) {
        selectedStreamDataRef.current = {
          ...selectedStreamData,
          startDate: toTransitionStreamData.startDate,
        };
      }
      return;
    }

    // if both are scheduled streams - change to other scheduled stream right away
    if (toTransitionStreamData.isScheduled && selectedStreamData?.isScheduled) {
      switchStream(toTransitionStreamData, '[3] toTransitionStreamData.isScheduled || selectedStreamData?.isScheduled');
      return;
    }

    // if is scheduled stream but new is running stream - change right away
    if (selectedStreamData?.isScheduled && toTransitionStreamData.sessionId) {
      switchStream(toTransitionStreamData, '[4] selectedStreamData?.isScheduled && toTransitionStreamData.sessionId');
      return;
    }

    // it is running stream, so if there was no starting time yet, start stream right away
    if (!lastStreamChangeTime.current) {
      switchStream(toTransitionStreamData, '[5] !lastStreamChangeTime.current');
      return;
    }

    // if there was staring time - check if it was ago enough to show popup with switch
    if (toTransitionStreamData.isSwitchTime && !globalContext?.isSwitchPopupVisible) {
      // display switching popup with cancelation option (by clicking the popup)
      globalContext?.setIsSwitchCountdownFinished(false);
      globalContext?.setIsSwitchPopupVisible(true);
      globalContext?.setNextStream(toTransitionStreamData);
    }
  }, [switchStream, globalContext]);

  const refreshScheduledStream = useCallback((newStream: SelectedStream) => {
    if (!newStream.streamData) {
      return;
    }

    if (isDebugLogForFeaturedStreams) {
      console.log('refreshScheduledStream', newStream);
    }
    const convertedStream = convertStreamDataToSimpleStream(newStream.streamData, newStream.isScheduled);
    const selectedStreamData = selectedStreamDataRef.current;
    // no stream at moment of checking, so set one right away
    if (!selectedStreamData) {
      transitionToStream(convertedStream);
      return;
    }

    // we already have a stream set, need to compare if we should replace it with new one
    const newRunningStream = newStream.streamData as ScheduledStream;

    // if those are different streams - meaning that the returned one is starting sooner
    if (selectedStreamData.eventId !== newRunningStream.eventId) {
      transitionToStream(convertedStream);
    }
  }, [transitionToStream]);

  const refreshRunningStream = useCallback((newStream: SelectedStream) => {
    if (!newStream.streamData) {
      return;
    }

    const selectedStreamData = selectedStreamDataRef.current;
    const convertedStream = convertStreamDataToSimpleStream(
      newStream.streamData,
      newStream.isScheduled,
      newStream.isSwitchTime,
    );

    // no stream at moment of checking, so set one right away
    if (!selectedStreamData || selectedStreamData.isScheduled) {
      transitionToStream(convertedStream);
      return;
    }
    // currently running stream has lower number of viewers
    const isAudienceFactorRequirementsMeet = shouldSwitchStreamByAudienceFactor(
      selectedStreamData.subscriptionCount,
      convertedStream.subscriptionCount,
    );

    if (isAudienceFactorRequirementsMeet) {
      transitionToStream(convertedStream);
    }
  }, [transitionToStream]);

  const updateCurrentStreamRefData = (currentStreamDataToUpdate: FeaturedStream) => {
    if (!selectedStreamDataRef.current) {
      return;
    }
    selectedStreamDataRef.current = {
      ...selectedStreamDataRef.current,
      subscriptionCount: currentStreamDataToUpdate.subscription_count,
    };
  };

  // check if should switch scheduled stream or running stream to more popuplar stream
  const checkIfReloadStreams = useCallback(() => {
    if (isDebugLogForFeaturedStreams) {
      console.log(
        '%c##### checkIfReloadStreams #####',
        'color: darkblue; font-weight: bold; background-color: lightblue',
        lastStreamChangeTime.current,
      );
    }

    const selectedStreamData = selectedStreamDataRef.current;
    getActiveStream({ bookStyle, selectedStreamData }).then((newStream) => {
      if (isDebugLogForFeaturedStreams) {
        console.log('%c \tnewStream', 'color: red', newStream);
      }
      if (!newStream) {
        return;
      }

      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }

      refreshTimeoutRef.current = setTimeout(checkIfReloadStreams, newStream.refreshTimeout ?? streamRefreshTime);

      if (!newStream.streamData) {
        setIsLoading(true);
        return;
      }
      if (newStream.isScheduled) {
        refreshScheduledStream(newStream);
        return;
      }

      if (newStream.currentStreamDataToUpdate && selectedStreamDataRef.current) {
        updateCurrentStreamRefData(newStream.currentStreamDataToUpdate);
      }
      refreshRunningStream(newStream);
    });
  }, [refreshRunningStream, refreshScheduledStream, bookStyle]);

  const refreshFeaturedStreamView = useCallback(() => {
    // if (isLoading) {
    //   return;
    // }
    if (isDebugLogForFeaturedStreams) {
      console.log('%c !!!!!!refreshFeaturedStreamView!!!!!!', 'color: purple');
    }
    setIsLoading(true);
    clearState();

    checkIfReloadStreams();
  }, [checkIfReloadStreams, clearState]);

  const onScheduledStreamCancelled = useCallback(() => {
    if (isDebugLogForFeaturedStreams) {
      console.log('%c ->>>>onScheduledStreamCancelled', 'color: red');
    }
    clearState();
    checkIfReloadStreams();
  }, [checkIfReloadStreams, clearState]);

  const onScheduledStreamStart = useCallback((eventId: number, sessionId: number) => {
    if (isDebugLogForFeaturedStreams) {
      console.log('%c ->>>>onScheduledStreamStart', 'color: blue', eventId, sessionId);
    }
    const selectedStreamData = selectedStreamDataRef.current;
    const streamData = {
      ...selectedStreamData,
      isScheduled: false,
      sessionId,
    } as SimpleSelectedStream;
    if (isDebugLogForFeaturedStreams) {
      console.log('\t->>>>onScheduledStreamStart', streamData);
    }
    selectStream(streamData);
  }, [selectStream]);

  useEffect(() => {
    document.body.classList.add(streamPageStyles.body);
    return () => {
      document.body.classList.remove(streamPageStyles.body);
    };
  }, [streamPageStyles.body]);

  useEffect(() => {
    selectedStreamDataRef.current = null;
    checkIfReloadStreams();
    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (globalContext && globalContext.isSwitchCountdownFinished) {
      globalContext.setIsSwitchCountdownFinished(false);
      globalContext.setIsSwitchPopupClicked(false);
      globalContext.setIsSwitchPopupVisible(false);

      if (globalContext.nextStream) {
        // console.log(
        //   '%c ^^^^^^ TRANSITION ^^^^^ TO ^^^^^ NEXT STREAM',
        //   'background-color: red; color: brown',
        //   globalContext.nextStream,
        // );
        transitionToStream(globalContext.nextStream, true);
        globalContext.setNextStream(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext?.isSwitchCountdownFinished]);
  const { gameplayWidth, gameplayHeight } = getSizeConfig();
  return (
    <div
      style={{
        width: gameplayWidth,
        height: gameplayHeight,
      }}
    >
      <Preloader isLoading={isLoading} />
      {(scheduledStreamId || runningStreamId) && (
        <div>
          <StreamManager
            scheduledStreamIdFromProps={scheduledStreamId}
            runningStreamIdFromProps={runningStreamId}
            avatarUrlFromProps={avatarUrl}
            refreshFeaturedStreamView={refreshFeaturedStreamView}
            isFeaturedViewFromProps
            onCancelStreamCallback={onScheduledStreamCancelled}
            onStartStreamCallback={onScheduledStreamStart}
          />
        </div>
      )}
    </div>
  );
}
