import { useCallback, useEffect } from 'react';
import { useForceUpdate } from './useForceUpdate';

export function useForceReload(canBeReloaded: boolean) {
  const { forceReloadKey, forceUpdate } = useForceUpdate();

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden && canBeReloaded) {
      forceUpdate();
    }
  }, [canBeReloaded, forceUpdate]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [
    handleVisibilityChange,
  ]);
  return { forceReloadKey };
}
