import { Outlet } from 'react-router-dom';
import { getSizeConfig } from '../dorian-shared/getSizeConfig';
import { isMobile } from './isMobile';
import cs from './PageLayout.module.css';
import { PopupProvider } from './PopupProvider/PopupProvider';

export function PageLayoutV2() {
  const { width, height } = getSizeConfig();

  return isMobile ? (
    <div className={cs.streamContainer} style={{ width, height }}>
      <PopupProvider>
        <Outlet />
      </PopupProvider>
    </div>
  ) : (
    <div className={cs.container}>
      <div className={cs.streamContainer} style={{ width, height }}>
        <PopupProvider>
          <Outlet />
        </PopupProvider>
      </div>
    </div>
  );
}
