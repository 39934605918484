import { useEffect, useState } from 'react';
// import { DownloadAppSection } from '../../components/DownloadAppSection/DownloadAppSection';
import { DownloadDorianBottomBanner } from '../../components/DownloadDorianBottomBanner/DownloadDorianBottomBanner';
import { GetDorianAppSection } from '../../components/GetDorianAppSection/GetDorianAppSection';
import { StreamInfoSectionV2 } from '../../components/StreamInfoSectionV2/StreamInfoSectionV2';
import { StreamInfoSectionV2Small } from '../../components/StreamInfoSectionV2Small/StreamInfoSectionV2Small';
import { getSizeConfig } from '../../dorian-shared/getSizeConfig';
import { StreamStatus } from '../ActiveStreamPage/Stream/StreamStatus/StreamStatus';
import cs from './StreamPage.module.css';
import { StreamPageBottom } from './StreamPageBottom';
import { useStreamPageStyles } from './StreamPageStyles';

export interface StreamPageProps {
  coverImageURL: string;
  eventTimeTitle: string;
  eventTitle: string;
  hostUsername: string;
  hostAvatarUrl: string;
  isFinished: boolean;
  startTime?: string;
  isFeaturedView?: boolean;
  refreshFeaturedStreamView?: () => void;
  episodeId: string;
}

export function StreamPage(props: StreamPageProps) {
  const {
    coverImageURL, eventTitle, eventTimeTitle, hostUsername, hostAvatarUrl, isFinished,
    startTime, isFeaturedView, refreshFeaturedStreamView, episodeId,
  } = props;

  const streamPageStyles = useStreamPageStyles();
  const [isPreparing, setIsPreparing] = useState(false);

  useEffect(() => {
    if (!isFeaturedView || !refreshFeaturedStreamView) {
      return () => undefined;
    }
    let preparingTimeout: ReturnType<typeof setTimeout> | null = null;
    if (isPreparing) {
      preparingTimeout = setTimeout(() => {
        setIsPreparing(false);
        refreshFeaturedStreamView();
      }, 10 * 60 * 1000);
    }
    return () => {
      if (preparingTimeout) {
        clearTimeout(preparingTimeout);
      }
    };
  }, [isPreparing, isFeaturedView, refreshFeaturedStreamView]);

  useEffect(() => {
    document.body.classList.add(streamPageStyles.body);
    return () => {
      document.body.classList.remove(streamPageStyles.body);
    };
  }, [streamPageStyles.body]);

  useEffect(() => {
    setIsPreparing(false);
  }, [episodeId]);

  const { gameplayWidth, gameplayHeight } = getSizeConfig();
  return (
    <>
      <div className={streamPageStyles.getDorianApp}>
        <GetDorianAppSection />
      </div>
      <div className={streamPageStyles.streamContainerWithPaddings}>
        <div className={streamPageStyles.streamContainer} style={{ width: gameplayWidth, height: gameplayHeight }}>
          <img
            className={isFinished ? cs.coverImageFinished : cs.coverImage}
            src={coverImageURL}
            alt="cover"
          />
          <div className={cs.coverOverlay}>
            <StreamStatus
              viewsCount={0}
              mainTitle={eventTitle}
              hostAvatarUrl={hostAvatarUrl}
              subTitle={hostUsername}
              isTitleReversed
              isIconsVisible={false}
            />

            {!isFinished && (
              <StreamPageBottom
                isPreparing={isPreparing}
                startTime={startTime}
                setIsPreparing={setIsPreparing}
              />
            )}
          </div>
          {/* {isFinished && <DownloadAppSection />} */}
          {isFinished && <DownloadDorianBottomBanner />}
        </div>
      </div>
      <div className={streamPageStyles.streamInfo}>
        <StreamInfoSectionV2
          eventTimeTitle={eventTimeTitle}
          eventTitle={eventTitle}
          hostName={hostUsername}
          hostAvatarUrl={hostAvatarUrl}
          isShowMoreStreamsLink={isFinished}
        />
      </div>
      <div className={streamPageStyles.streamInfoSmall}>
        <StreamInfoSectionV2Small
          eventTimeTitle={eventTimeTitle}
          eventTitle={eventTitle}
          hostName={hostUsername}
          hostAvatarUrl={hostAvatarUrl}
          isShowMoreStreamsLink={isFinished}
        />
      </div>
    </>
  );
}
