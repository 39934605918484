import { useState } from 'react';
import { ToolTip } from '../../../../../dorian-shared/components/ToolTip/ToolTip';
// import { MessageIcon } from '../../../../../icons/MessageIcon';
import { useGlobalStateContext } from '../../../../../providers/GlobalStateProvider';
import { MuteButton } from '../../../../components/MuteButton/MuteButton';
import { useStreamAudios } from '../../hooks/useStreamAudios';
import { LiveNow } from './LiveNow/LiveNow';
import cs from './StreamHeaderTop.module.css';

type StreamHeaderTopProps = {
  isStreamExist?: boolean,
  // isNewChatMessage?: boolean,
  // handleChatIconClick?: () => void,
}

export function StreamHeaderTop(props: StreamHeaderTopProps) {
  const { isStreamExist /* , handleChatIconClick, isNewChatMessage */ } = props;
  const { isMuted, onMuteChange, shouldMuteButtonVisible } = useStreamAudios();
  const [isMuteClicked, setIsMuteClicked] = useState(false);

  const globalState = useGlobalStateContext();

  // const onClickChat = useCallback(() => {
  //   if (handleChatIconClick) {
  //     handleChatIconClick();
  //   }
  // }, [handleChatIconClick]);

  const onMuteChangeWrapper = (value: boolean) => {
    onMuteChange(value);
    setIsMuteClicked(true);
  };

  const isToolTipVisible = globalState?.appId === 'arcana';
  return (
    <div className={cs.wrapper}>
      {isStreamExist && <LiveNow />}
      <div className={cs.iconsWrapper}>
        { /* <div className={cs.chatIcon}>
          <MessageIcon onClick={onClickChat} sx={{ fontSize: '0.8em' }} />
          {isNewChatMessage
          && <div className={cs.chatIconRedDot} /> }
        </div> */ }
        {shouldMuteButtonVisible && isStreamExist && (
          <MuteButton
            isMuted={isMuted}
            onMuteChange={onMuteChangeWrapper}
          />
        )}

        {isToolTipVisible
        && (
        <ToolTip
          text="Tap to Listen!"
          styles={{
            right: 'calc(0.5 * var(--space))',
            left: 'auto',
            top: 'calc(4px + var(--header-top-height))',
          }}
          isHiddenFromOutside={isMuteClicked}
        />
        )}

      </div>
    </div>
  );
}
