import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { EpisodeContentResponseData } from '../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';
import { Advance } from '../../../dorian-shared/types/stream-socket/Advance';
import { LeavingSocketMessage } from '../../../dorian-shared/types/stream-socket/LeavingSocketMessage';
import { MultiplayerStartedMessage } from '../../../dorian-shared/types/stream-socket/MultiplayerStartedMessage';
import { NewHostSocketMessage } from '../../../dorian-shared/types/stream-socket/NewHostSocketMessage';
import { ParticipantsSocketMessage } from '../../../dorian-shared/types/stream-socket/ParticipantsSocketMessage';
import { SetNextEpisodeSocketMessage } from '../../../dorian-shared/types/stream-socket/SetNextEpisodeSocketMessage';
import {
  SomeoneConnectedToRoomSocketMessage,
} from '../../../dorian-shared/types/stream-socket/SomeoneConnectedToRoomSocketMessage';
import { useAuthKeyPair } from '../../../providers/AuthProvider';
import { useGlobalStateContext } from '../../../providers/GlobalStateProvider';
import { useMultiplayerContext } from '../../../providers/MultiPlayerProvider';
import { MultiplayerEvent } from '../../../services/multiplayerService/multiplayerService';
import { s3EngineObjectsCache } from '../../../services/s3EngineObjectsCache';
import { BaseParticipant } from '../types/BaseParticipant';
import { StreamState } from '../types/StreamState';
import { StreamStateData } from '../types/StreamStateData';
import { useBeforeBrowserClose } from './hooks/useBeforeBrowserClose';
import { useStreamMinutesCounter } from './hooks/useStreamMinutesCounter';
import { convertParticipantsToMapById, getStreamDataFromAdvanceAndContent } from './streamUtils';
import { useLargeVideoPlayerId } from './useLargeVideoPlayerId';

async function loadEpisodeContent(episodeUuid: string | undefined) {
  if (!episodeUuid) {
    return undefined;
  }
  try {
    const response = await s3EngineObjectsCache.get<EpisodeContentResponseData>(
      `/getEpisodeContent/${episodeUuid}?t=${Date.now()}`,
    );
    // setEpisodeContent(response.data);
    return response.data;
  } catch (e) {
    // TODO: handle error
    return undefined;
  }
}

export function useStreamDataV2(
  multiplayerId: number,
  episodeId: string,
  setEpisodeId: (episodeId: string, source?: string) => void,
  isStreamExist?: boolean,
) {
  const [participantsByPlayerId, setParticipantsByPlayerId] = useState<Record<string, BaseParticipant>>({});
  const [viewsCount, setViewsCount] = useState(0);
  const [episodeContent, setEpisodeContent] = useState<EpisodeContentResponseData | undefined>();
  const isEpisodeContentLoading = useRef<boolean>(false);

  const multiplayerService = useMultiplayerContext();
  const globalState = useGlobalStateContext();
  const appId = globalState?.appId;

  const authKeyPair = useAuthKeyPair();

  const [streamData, setStreamData] = useState<StreamStateData>({
    state: StreamState.StreamIsOver,
  });

  useStreamMinutesCounter(multiplayerId);

  const sendDisconnectEvent = useCallback(() => {
    multiplayerService.leaveWeb(multiplayerId, authKeyPair);
    multiplayerService.disconnect();
  }, [authKeyPair, multiplayerId, multiplayerService]);

  useBeforeBrowserClose(sendDisconnectEvent);

  const largeVideoPlayerId = useLargeVideoPlayerId();

  const handleMultiplayerStarted = useCallback((data: MultiplayerStartedMessage) => {
    if (episodeContent) {
      const stateData = getStreamDataFromAdvanceAndContent(data.lastAdvance, episodeContent);
      setStreamData(stateData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeContent]);

  const handleMultiplayerAdvance = useCallback((data: Advance) => {
    if (episodeContent) {
      const stateData = getStreamDataFromAdvanceAndContent(data, episodeContent);
      setStreamData(stateData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeContent]);

  const handleMultiplayerNewHost = useCallback((data: NewHostSocketMessage) => {
    const {
      playerId: newHostPlayerId,
    } = data;

    if (!newHostPlayerId) {
      setStreamData((data) => ({ ...data, state: StreamState.HostLeftStream }));
      setTimeout(() => {
        setStreamData((data) => ({ ...data, state: StreamState.StreamIsOver }));
      }, 500);
      return;
    }

    setParticipantsByPlayerId((participantsByPlayerId) => {
      if (!newHostPlayerId) {
        return participantsByPlayerId;
      }

      const oldHostPlayerId = Object
        .values(participantsByPlayerId)
        .find((participant) => participant.host)
        ?.playerId;

      if (!oldHostPlayerId) {
        return participantsByPlayerId;
      }

      const {
        [newHostPlayerId]: newHostParticipant,
        [oldHostPlayerId]: _,
        ...otherParticipants
      } = participantsByPlayerId;

      return {
        ...otherParticipants,
        [newHostPlayerId]: {
          ...newHostParticipant,
          host: true,
        },
        [oldHostPlayerId]: {
          ...newHostParticipant,
          host: false,
        },
      };
    });
  }, []);

  const handleParticipants = useCallback((data: ParticipantsSocketMessage) => {
    const { participants } = data;
    const newParticipantsById = convertParticipantsToMapById(participants);
    setParticipantsByPlayerId(newParticipantsById);
  }, []);

  const handleLeaving = useCallback((data: LeavingSocketMessage) => {
    const { playerId } = data;

    setParticipantsByPlayerId((participantsByPlayerId) => {
      const { [playerId]: leavingParticipant, ...otherParticipants } = participantsByPlayerId;
      return otherParticipants;
    });
  }, []);

  const handleSomeoneConnectedToRoom = useCallback((data: SomeoneConnectedToRoomSocketMessage) => {
    const { profile, totalAppCount, totalWebCount } = data;

    const newTotalAppCount = totalAppCount != null ? totalAppCount : 0;
    const newTotalWebCount = totalWebCount != null ? totalWebCount : 0;
    setViewsCount(newTotalAppCount + newTotalWebCount);

    setParticipantsByPlayerId((participantsByPlayerId) => ({
      ...participantsByPlayerId,
      [profile.playerId]: {
        ...participantsByPlayerId,
        ...profile,
      },
    }));
  }, []);

  const handleSetNextEpisode = useCallback((data: SetNextEpisodeSocketMessage) => {
    const { episodeId: newEpisodeId } = data;
    setEpisodeId(newEpisodeId);
    // console.log('%c handleSetNextEpisode setEpisodeId', 'color: blue', newEpisodeId);
  }, [setEpisodeId]);

  useEffect(() => {
    multiplayerService.addListener(MultiplayerEvent.MultiplayerStarted, handleMultiplayerStarted);
    multiplayerService.addListener(MultiplayerEvent.Advance, handleMultiplayerAdvance);
    multiplayerService.addListener(MultiplayerEvent.NewHost, handleMultiplayerNewHost);
    multiplayerService.addListener(MultiplayerEvent.Participants, handleParticipants);
    multiplayerService.addListener(MultiplayerEvent.Leaving, handleLeaving);
    multiplayerService.addListener(MultiplayerEvent.SomeoneConnectedToRoom, handleSomeoneConnectedToRoom);
    multiplayerService.addListener(MultiplayerEvent.SetNextEpisode, handleSetNextEpisode);
    return () => {
      multiplayerService.removeListener(MultiplayerEvent.MultiplayerStarted, handleMultiplayerStarted);
      multiplayerService.removeListener(MultiplayerEvent.Advance, handleMultiplayerAdvance);
      multiplayerService.removeListener(MultiplayerEvent.NewHost, handleMultiplayerNewHost);
      multiplayerService.removeListener(MultiplayerEvent.Participants, handleParticipants);
      multiplayerService.removeListener(MultiplayerEvent.Leaving, handleLeaving);
      multiplayerService.removeListener(MultiplayerEvent.SomeoneConnectedToRoom, handleSomeoneConnectedToRoom);
      multiplayerService.removeListener(MultiplayerEvent.SetNextEpisode, handleSetNextEpisode);
    };
  }, [
    handleLeaving,
    handleMultiplayerAdvance,
    handleMultiplayerNewHost,
    handleMultiplayerStarted,
    handleParticipants,
    handleSomeoneConnectedToRoom,
    multiplayerService,
    handleSetNextEpisode,
  ]);

  useEffect(() => {
    if (!isStreamExist) {
      return;
    }

    if (isEpisodeContentLoading.current) {
      return;
    }

    if (episodeContent && episodeContent.setting.uuid === episodeId) {
      return;
    }

    isEpisodeContentLoading.current = true;
    loadEpisodeContent(episodeId).then((episodeContent) => {
      const initialStreamState = StreamState.PreStreamLobby;
      // const isStreamExists = Boolean(episodeId);
      // const initialStreamState = isStreamExists ? StreamState.PreStreamLobby : StreamState.StreamIsOver;
      setEpisodeContent(episodeContent);
      setStreamData({
        state: initialStreamState,
      });
      isEpisodeContentLoading.current = false;
      if (!multiplayerService.isConnected) {
        multiplayerService.connect();
        multiplayerService.on(multiplayerId, authKeyPair);
        multiplayerService.join(multiplayerId, authKeyPair, appId);
      }
    }).catch(() => {
      isEpisodeContentLoading.current = false;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authKeyPair,
    multiplayerId,
    multiplayerService,
    episodeId,
    isStreamExist,
    appId,
  ]);

  useEffect(() => () => {
    multiplayerService.leaveWeb(multiplayerId, authKeyPair);
    multiplayerService.disconnect();
  }, [authKeyPair, multiplayerId, multiplayerService]);

  const startStreamAfterCountdown = useCallback(() => {
    if (streamData.state === StreamState.InitialCountdown) {
      setStreamData({
        state: StreamState.InProgress,
        advance: streamData.advance,
      });
    } else {
      // TODO: sentry: we should not call this function from state other than INITIAL_COUNTDOWN
    }
  }, [streamData]);

  return {
    streamData,
    startStreamAfterCountdown,
    participantsByPlayerId,
    viewsCount,
    largeVideoPlayerId,
    multiPlayerSocket: multiplayerService.getSocket(),
    episodeContent,
    // currentEpisodeId,
  };
}
