import { Box, SxProps, Theme } from '@mui/material';
import cs from './StreamOverlay.module.css';

interface StreamOverlayProps {
  onClick?: () => void
  title?: string
  className?: string
  sx?: SxProps<Theme>
}

export function StreamOverlay(props: StreamOverlayProps) {
  const {
    onClick,
    title,
    className,
    sx,
  } = props;

  return (
    <Box
      className={className ?? cs.container}
      onClick={onClick}
      title={title}
      sx={sx}
    />
  );
}
