import { BookCover } from '../dorian-shared/types/multiplayerServer/EpisodeInfo';
import launch from '../images/backgrounds/launch.jpg';
import { getFullURL } from '../services/s3EngineObjectsCache';

export const COVER_URL_FALLBACK = launch;

// export function getCoverUrl(url: string | undefined): string {
//   if (url) {
//     return getFullURL(url);
//   }
//   return COVER_URL_FALLBACK;
// }

export function getCoverUrl(bookCover: BookCover | undefined): string {
  const bookCoverStr = bookCover?.medium ?? bookCover?.['1024x1024'] ?? bookCover?.unedited ?? undefined;
  if (bookCoverStr) {
    return getFullURL(bookCoverStr);
  }
  return COVER_URL_FALLBACK;
}
