import { Box, Button } from '@mui/material';
import { EmojiIcon } from '../../../../icons/EmojiIcon';
import { HeartIcon } from '../../../../icons/HeartIcon';
import { ReactComponent as InfoIcon } from '../../../../icons/InfoIcon.svg';
// import { MessageIcon } from '../../../../icons/MessageIcon';
import { usePopupDownload } from '../PopupDownload/PopupDownloadProvider';
import cs from './StreamFooter.module.css';

// type StreamFooterProps = {
//   handleChatIconClick?: () => void,
// }

export function StreamFooter(/* props: StreamFooterProps */) {
  // const { handleChatIconClick } = props;
  const { showPopup } = usePopupDownload();

  // const clickChatIcon = () => {
  //   if (handleChatIconClick) {
  //     handleChatIconClick();
  //     return;
  //   }
  //
  //   showPopup();
  // };
  return (
    <div className={cs.container}>
      <Button
        className={cs.heartIcon}
        onClick={showPopup}
      >
        <HeartIcon fontSize="large" />
      </Button>
      { /* <Button
        size="large"
        className={cs.filledButton}
        onClick={clickChatIcon}
      >
        <MessageIcon />
      </Button> */ }
      <Box ml={2}>
        <Button
          size="large"
          className={cs.filledButton}
          onClick={showPopup}
        >
          <EmojiIcon />
        </Button>
      </Box>

      <div className={cs.infoContainer}>
        <InfoIcon onClick={showPopup} className={cs.infoIcon} />
      </div>
    </div>
  );
}
