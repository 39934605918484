import { CSSProperties, useState } from 'react';
import cs from './ToolTip.module.css';

export enum ToolTipPointerPlacement {
  TOP_RIGHT,
  TOP_CENTER,
  TOP_LEFT,
}

interface ToolTipProps {
  text: string,
  styles?: CSSProperties,
  isHiddenFromOutside?: boolean,
}

export function ToolTip(props: ToolTipProps) {
  const {
    text, styles, isHiddenFromOutside = false,
  } = props;
  const [isHidden, setIsHidden] = useState(false);
  const styleToAdd: CSSProperties = { ...styles };

  const onClickToolTip = () => {
    console.log('onClickToolTip');
    setIsHidden(true);
  };
  return (
    <div>
      {(!isHidden && !isHiddenFromOutside) && (
        <div className={cs.wrapper} onClick={onClickToolTip} aria-hidden="true" style={styleToAdd}>
          <div className={cs.pointer} />
          {text}
        </div>
      )}
    </div>
  );
}
