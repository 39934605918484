import MoreIcon from '../../../../../icons/MoreIcon.svg';
import ProfileIcon from '../../../../../icons/ProfileIcon.svg';
import ShareIcon from '../../../../../icons/ShareArrowIcon.svg';
import { usePopupDownload } from '../../PopupDownload/PopupDownloadProvider';
// import { useDownloadPopup } from '../../hooks/useDownloadPopup';
import cs from './StreamStatusIcons.module.css';

export interface StreamStatusIconsProps {
    viewsCount: number,
}
export function StreamStatusIcons(props: StreamStatusIconsProps) {
  const { viewsCount } = props;
  // const showDownloadPopup = useDownloadPopup();
  const { showPopup } = usePopupDownload();

  return (
    <div className={cs.iconsWrapper}>
      <div className={cs.audienceButtonWrapper}>
        <button
          type="button"
          onClick={showPopup}
        >
          <img src={ProfileIcon} alt="Audience" />
          <span className={cs.audienceCount}>{viewsCount}</span>
        </button>
      </div>
      <div className={cs.shareButtonWrapper}>
        <button
          type="button"
          onClick={showPopup}
        >
          <img src={ShareIcon} alt="Share" />
        </button>
      </div>
      <div className={cs.moreButtonWrapper}>
        <button
          type="button"
          onClick={showPopup}
        >
          <img src={MoreIcon} alt="More" />
        </button>
      </div>
    </div>
  );
}
