import {
  differenceInSeconds, parseISO,
} from 'date-fns';
import { getAuthKeyPairFromLocalStorage } from '../../providers/AuthProvider';
import { mediaServer } from '../../services/mediaServer';
import { multiplayerServer } from '../../services/multiplayerServer';
import { ScheduledStream } from '../ScheduledStreamPage/types/ScheduledStream';
import { isDebugLogForFeaturedStreams } from './FeaturedStreamPage';
import {
  FallbackUrlResult,
  FeaturedStream,
  FeaturedStreamLobbiesResult,
} from './types/FeaturedStream';

export type StreamTypesCombined = FeaturedStream | ScheduledStream;
export type SelectedStream = {
  streamData: StreamTypesCombined | null,
  isScheduled: boolean,
  refreshTimeout?: number,
  isSwitchTime?: boolean,
  currentStreamDataToUpdate: FeaturedStream | null,
}

export type SimpleSelectedStream = {
  isScheduled: boolean,
  eventId?: number,
  sessionId?: number, // streamId
  subscriptionCount: number,
  playerId: string,
  storyId: number,
  startDate?: string | null,
  avatarUrl?: string,
  isSwitchTime?: boolean,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function selectStreamByAudienceFactor(list: StreamTypesCombined[]) {
  // eslint-disable-next-line max-len
  return list.reduce((streamWithHighestAudienceFactor: StreamTypesCombined, stream: StreamTypesCombined) => (streamWithHighestAudienceFactor.subscription_count > stream.subscription_count ? streamWithHighestAudienceFactor : stream));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function selectStreamByDate(list: StreamTypesCombined[]) {
  let selectedStream: StreamTypesCombined | null = null;
  let startedInTime = Infinity;
  const selectedLobbies: StreamTypesCombined[] = [];

  const currentDate = new Date();
  list.forEach((stream) => {
    if (stream.startDate) {
      const startTimeISO = parseISO(stream.startDate) ?? 0;
      const secondsDifference = differenceInSeconds(startTimeISO, currentDate);

      if (secondsDifference <= startedInTime) { // && secondsDifference > 0) {
        selectedStream = stream;
        startedInTime = secondsDifference;
        selectedLobbies.push(selectedStream);
      }
    }
  });

  if (selectedLobbies.length > 1 && selectedStream) {
    const { startDate: selectedStreamStartDate } = selectedStream;
    const finalListOfStreams: StreamTypesCombined[] = [];
    selectedLobbies.forEach((stream) => {
      if (stream.startDate === selectedStreamStartDate) {
        finalListOfStreams.push(stream);
      }
    });

    selectedStream = selectStreamByAudienceFactor(finalListOfStreams);
  }
  return selectedStream;
}

function selectFeaturedStream(
  lobbies: FeaturedStream[],
  scheduled: ScheduledStream[],
  refreshTimeout = 60000,
  isSwitchTime = false,
  currentStreamDataToUpdate: FeaturedStream | null = null,
): SelectedStream {
  if (lobbies.length === 0) {
    return {
      // streamData: selectStreamByAudienceFactor(scheduled) as ScheduledStream,
      streamData: selectStreamByDate(scheduled),
      isScheduled: true,
      refreshTimeout,
      isSwitchTime,
      currentStreamDataToUpdate,
    };
  }
  return {
    streamData: selectStreamByAudienceFactor(lobbies) as FeaturedStream,
    isScheduled: false,
    refreshTimeout,
    isSwitchTime,
    currentStreamDataToUpdate,
  };
}

export async function getAlternativeStreamUrl(): Promise<string | null> {
  try {
    const res = await mediaServer.get<FallbackUrlResult>('/streams/featured');
    if (res && res.data.ok && res.data.data) {
      return res.data.data.videoUrl;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export function loadNextVideoWithDelay(
  callback: (isForced: boolean) => void,
  currentTimeout?: ReturnType<typeof setTimeout>,
  isForced = false,
) {
  if (currentTimeout) {
    clearTimeout(currentTimeout);
  }
  return setTimeout(() => {
    callback(isForced);
  }, 5000);
}

function getCurrentStreamDataToUpdate(
  data: FeaturedStreamLobbiesResult,
  selectedStreamData: SimpleSelectedStream | null,
): FeaturedStream | null {
  if (!selectedStreamData || selectedStreamData.isScheduled) {
    return null;
  }

  if (!data.lobbies || data.lobbies.length === 0) {
    return null;
  }

  const lobby = data.lobbies.find((lobby) => lobby.sessionId === selectedStreamData.sessionId);
  return lobby ?? null;
}

interface GetActiveStreamProps {
  testStreamId?: number | null
  bookStyle?: string | null
  selectedStreamData: SimpleSelectedStream | null
}

export async function getActiveStream(props: GetActiveStreamProps): Promise<SelectedStream | null> {
  const { bookStyle = null, selectedStreamData } = props;
  try {
    const authKeyPair = getAuthKeyPairFromLocalStorage();
    const postData = {
      appName: bookStyle,
      ...authKeyPair,
    };
    // old endpoints: getLiveLobbiesForStripWeb, getMultiplayerLobbiesWeb, getMultiplayerLobbiesForStripWeb
    // new endpoint: getMultiplayerLobbiesForStripWebV2
    const res = await multiplayerServer.post<FeaturedStreamLobbiesResult>(
      '/getMultiplayerLobbiesForStripWebV3',
      postData,
    );

    if (res.data) {
      const currentStreamDataToUpdate = getCurrentStreamDataToUpdate(res.data, selectedStreamData);
      const {
        lobbies, scheduledStreams, refreshTimeout, isSwitchTime,
      } = res.data;
      const selectedFeaturedStream = selectFeaturedStream(
        lobbies,
        scheduledStreams,
        refreshTimeout,
        isSwitchTime,
        currentStreamDataToUpdate,
      );

      return selectedFeaturedStream;
    }
  } catch (error) {
    console.error('Missing lobbies for featured stream');

    return {
      streamData: null,
      isScheduled: false,
      isSwitchTime: false,
      refreshTimeout: 30000,
      currentStreamDataToUpdate: null,
    };
  }

  return null;
}

export function shouldSwitchStreamByAudienceFactor(streamCurrentCount: number, streamNewCount: number) {
  const differenceIncreaseFactor = 1.25; // 25 %
  return Math.floor(streamCurrentCount * differenceIncreaseFactor) < streamNewCount;
}

export const streamDataCompare = {
  DIFFERENT: 1,
  SAME: 2,
  SAME_BUT_RUNNING: 3,
};

function isStreamDataTheSameByPlayer(stream1Data: SimpleSelectedStream, stream2Data: SimpleSelectedStream) {
  if (stream1Data.playerId !== stream2Data.playerId) {
    return false;
  }

  return true;
}

export function isStreamDataTheSame(stream1Data: SimpleSelectedStream, stream2Data: SimpleSelectedStream) {
  if (isDebugLogForFeaturedStreams) {
    console.log('isStreamDataTheSame 1', stream1Data);
    console.log('isStreamDataTheSame 2', stream2Data);
  }
  if (stream1Data.isScheduled !== stream2Data.isScheduled) {
    if (isDebugLogForFeaturedStreams) {
      console.log('isStreamDataTheSame 3');
    }
    return false;
  }

  if (stream1Data.isScheduled && stream2Data.isScheduled) {
    if (isDebugLogForFeaturedStreams) {
      console.log('isStreamDataTheSame 4');
    }
    if (stream1Data.eventId !== stream2Data.eventId) {
      return false;
    }

    return isStreamDataTheSameByPlayer(stream1Data, stream2Data);
  }

  if (stream1Data.sessionId !== stream2Data.sessionId) {
    return false;
  }

  return isStreamDataTheSameByPlayer(stream1Data, stream2Data);
}

export function convertStreamDataToSimpleStream(
  streamData: StreamTypesCombined,
  isScheduled = false,
  isSwitchTime = false,
): SimpleSelectedStream {
  let eventId: number | undefined;
  let sessionId: number | undefined;

  if (isScheduled) {
    const scheduledStreamData = streamData as ScheduledStream;
    eventId = scheduledStreamData.eventId;
  } else {
    const featuredStreamData = streamData as FeaturedStream;
    sessionId = featuredStreamData.sessionId;
    eventId = featuredStreamData.eventId;
  }
  const {
    player_id: playerId, story_id: storyId, startDate, avatar_url: avatarUrl,
  } = streamData;
  return {
    isScheduled,
    eventId,
    sessionId, // streamId
    subscriptionCount: streamData.subscription_count,
    playerId,
    storyId,
    startDate,
    avatarUrl,
    isSwitchTime,
  };
}
