import streamPrepareIcon from '../../icons/StreamPrepareIcon.svg';
import cs from './StreamPagePreparing.module.css';

export function StreamPagePreparing() {
  return (
    <div className={cs.wrapper}>
      <img src={streamPrepareIcon} alt="live" />
      <div className={cs.title}>
        Stream Preparing
        <br />
        to Start
      </div>
    </div>
  );
}
