import { Box, Link } from '@mui/material';
import React from 'react';
import DorianWhiteSimpleLogo from '../../icons/DorianWhiteLogo.svg';
import JulianIcon2 from '../../images/julian-1.png';
import JulianIcon1 from '../../images/julian.png';
import { DOWNLOAD_APP_URL } from '../../services/staticUrls';
import cs from './DownloadDorianBottomBanner.module.css';

export function DownloadDorianBottomBanner() {
  return (
    <Box className={cs.container}>
      <Link href={DOWNLOAD_APP_URL} target="_blank" className={cs.link}>
        <div className={cs.content}>
          <div className={cs.icon}>
            <img src={DorianWhiteSimpleLogo} alt="Dorian Logo" />
          </div>

          <div className={cs.header}>
            Download Dorian to join the live chat!
          </div>

          <div className={cs.rightContainer}>
            <img src={JulianIcon1} alt="" />
            <img src={JulianIcon2} alt="" />
          </div>
        </div>
      </Link>
    </Box>
  );
}
