import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Advance } from '../../../dorian-shared/types/stream-socket/Advance';
import { LeavingSocketMessage } from '../../../dorian-shared/types/stream-socket/LeavingSocketMessage';
import { MultiplayerStartedMessage } from '../../../dorian-shared/types/stream-socket/MultiplayerStartedMessage';
import { NewHostSocketMessage } from '../../../dorian-shared/types/stream-socket/NewHostSocketMessage';
import { ParticipantsSocketMessage } from '../../../dorian-shared/types/stream-socket/ParticipantsSocketMessage';
import { SetNextEpisodeSocketMessage } from '../../../dorian-shared/types/stream-socket/SetNextEpisodeSocketMessage';
import {
  SomeoneConnectedToRoomSocketMessage,
} from '../../../dorian-shared/types/stream-socket/SomeoneConnectedToRoomSocketMessage';
import { useAuthKeyPair } from '../../../providers/AuthProvider';
import { useGlobalStateContext } from '../../../providers/GlobalStateProvider';
import { useMultiplayerContext } from '../../../providers/MultiPlayerProvider';
import { MultiplayerEvent } from '../../../services/multiplayerService/multiplayerService';
import { BaseParticipant } from '../types/BaseParticipant';
import { StreamState } from '../types/StreamState';
import { StreamStateData } from '../types/StreamStateData';
import { useBeforeBrowserClose } from './hooks/useBeforeBrowserClose';
import { useStreamMinutesCounter } from './hooks/useStreamMinutesCounter';
import { convertParticipantsToMapById, getStreamDataFromAdvanceAndContent } from './streamUtils';
import { useEpisode } from './useEpisode';
import { useLargeVideoPlayerId } from './useLargeVideoPlayerId';

export function useStreamData(episodeId: string) {
  const [participantsByPlayerId, setParticipantsByPlayerId] = useState<Record<string, BaseParticipant>>({});
  const [viewsCount, setViewsCount] = useState(0);
  const [currentEpisodeId, setCurrentEpisodeId] = useState(episodeId);

  const multiplayerService = useMultiplayerContext();
  const globalState = useGlobalStateContext();
  const appId = globalState?.appId;

  const params = useParams();
  const multiplayerId = Number(params.multiplayerId);
  const authKeyPair = useAuthKeyPair();

  const episodeContent = useEpisode(currentEpisodeId);
  // const globalState = useGlobalStateContext();

  const isStreamExists = Boolean(episodeId);
  const initialStreamState = isStreamExists ? StreamState.PreStreamLobby : StreamState.StreamIsOver;

  const [streamData, setStreamData] = useState<StreamStateData>({
    state: initialStreamState,
  });

  const isStreamFinished = useMemo(() => streamData.state === StreamState.StreamIsOver
    || streamData.state === StreamState.HostLeftStream, [streamData.state]);

  useStreamMinutesCounter(multiplayerId);

  const sendDisconnectEvent = useCallback(() => {
    multiplayerService.leaveWeb(multiplayerId, authKeyPair);
    multiplayerService.disconnect();
  }, [authKeyPair, multiplayerId, multiplayerService]);

  useBeforeBrowserClose(sendDisconnectEvent);

  const largeVideoPlayerId = useLargeVideoPlayerId();

  // To avoid re-subscribe event when episodeContent changed
  const episodeContentRef = useRef(episodeContent);

  const handleMultiplayerStarted = useCallback((data: MultiplayerStartedMessage) => {
    if (episodeContentRef.current) {
      setStreamData(getStreamDataFromAdvanceAndContent(data.lastAdvance, episodeContentRef.current));
    }
  }, []);

  const handleMultiplayerAdvance = useCallback((data: Advance) => {
    if (episodeContentRef.current) {
      setStreamData(getStreamDataFromAdvanceAndContent(data, episodeContentRef.current));
    }
  }, []);

  const handleMultiplayerNewHost = useCallback((data: NewHostSocketMessage) => {
    const {
      playerId: newHostPlayerId,
    } = data;

    if (!newHostPlayerId) {
      setStreamData((data) => ({ ...data, state: StreamState.HostLeftStream }));
      setTimeout(() => {
        setStreamData((data) => ({ ...data, state: StreamState.StreamIsOver }));
      }, 500);
      return;
    }

    setParticipantsByPlayerId((participantsByPlayerId) => {
      if (!newHostPlayerId) {
        return participantsByPlayerId;
      }

      const oldHostPlayerId = Object
        .values(participantsByPlayerId)
        .find((participant) => participant.host)
        ?.playerId;

      if (!oldHostPlayerId) {
        return participantsByPlayerId;
      }

      const {
        [newHostPlayerId]: newHostParticipant,
        [oldHostPlayerId]: _,
        ...otherParticipants
      } = participantsByPlayerId;

      return {
        ...otherParticipants,
        [newHostPlayerId]: {
          ...newHostParticipant,
          host: true,
        },
        [oldHostPlayerId]: {
          ...newHostParticipant,
          host: false,
        },
      };
    });
  }, []);

  const handleParticipants = useCallback((data: ParticipantsSocketMessage) => {
    const { participants } = data;
    const newParticipantsById = convertParticipantsToMapById(participants);
    setParticipantsByPlayerId(newParticipantsById);
  }, []);

  const handleLeaving = useCallback((data: LeavingSocketMessage) => {
    const { playerId } = data;

    setParticipantsByPlayerId((participantsByPlayerId) => {
      const { [playerId]: leavingParticipant, ...otherParticipants } = participantsByPlayerId;
      return otherParticipants;
    });
  }, []);

  const handleSomeoneConnectedToRoom = useCallback((data: SomeoneConnectedToRoomSocketMessage) => {
    const { profile, totalAppCount, totalWebCount } = data;

    const newTotalAppCount = totalAppCount != null ? totalAppCount : 0;
    const newTotalWebCount = totalWebCount != null ? totalWebCount : 0;
    setViewsCount(newTotalAppCount + newTotalWebCount);

    setParticipantsByPlayerId((participantsByPlayerId) => ({
      ...participantsByPlayerId,
      [profile.playerId]: {
        ...participantsByPlayerId,
        ...profile,
      },
    }));
  }, []);

  const handleSetNextEpisode = useCallback((data: SetNextEpisodeSocketMessage) => {
    const { episodeId } = data;
    setCurrentEpisodeId(episodeId);
  }, []);

  useEffect(() => {
    episodeContentRef.current = episodeContent;
  }, [episodeContent]);

  useEffect(() => {
    multiplayerService.addListener(MultiplayerEvent.MultiplayerStarted, handleMultiplayerStarted);
    multiplayerService.addListener(MultiplayerEvent.Advance, handleMultiplayerAdvance);
    multiplayerService.addListener(MultiplayerEvent.NewHost, handleMultiplayerNewHost);
    multiplayerService.addListener(MultiplayerEvent.Participants, handleParticipants);
    multiplayerService.addListener(MultiplayerEvent.Leaving, handleLeaving);
    multiplayerService.addListener(MultiplayerEvent.SomeoneConnectedToRoom, handleSomeoneConnectedToRoom);
    multiplayerService.addListener(MultiplayerEvent.SetNextEpisode, handleSetNextEpisode);
    return () => {
      multiplayerService.removeListener(MultiplayerEvent.MultiplayerStarted, handleMultiplayerStarted);
      multiplayerService.removeListener(MultiplayerEvent.Advance, handleMultiplayerAdvance);
      multiplayerService.removeListener(MultiplayerEvent.NewHost, handleMultiplayerNewHost);
      multiplayerService.removeListener(MultiplayerEvent.Participants, handleParticipants);
      multiplayerService.removeListener(MultiplayerEvent.Leaving, handleLeaving);
      multiplayerService.removeListener(MultiplayerEvent.SomeoneConnectedToRoom, handleSomeoneConnectedToRoom);
      multiplayerService.removeListener(MultiplayerEvent.SetNextEpisode, handleSetNextEpisode);
    };
    // eslint-disable-next-line max-len
  }, [
    handleLeaving,
    handleMultiplayerAdvance,
    handleMultiplayerNewHost,
    handleMultiplayerStarted,
    handleParticipants,
    handleSomeoneConnectedToRoom,
    multiplayerService,
    handleSetNextEpisode,
  ]);

  useEffect(() => {
    if (isStreamFinished) {
      if (multiplayerService.isConnected) {
        multiplayerService.leaveWeb(multiplayerId, authKeyPair);
        multiplayerService.disconnect();
      }

      return;
    }
    if (!episodeContent) {
      return;
    }
    if (!multiplayerService.isConnected) {
      multiplayerService.connect();
      multiplayerService.on(multiplayerId, authKeyPair);
      multiplayerService.join(multiplayerId, authKeyPair, appId);
    }
    // globalState.resetFeaturedStreamsReloadCount();
    // globalState?.setFeaturedStreamsReloadCount(0);
  }, [
    authKeyPair,
    handleLeaving,
    isStreamFinished,
    multiplayerId,
    multiplayerService,
    episodeContent,
    appId,
    // globalState,
  ]);

  useEffect(() => () => {
    multiplayerService.leaveWeb(multiplayerId, authKeyPair);
    multiplayerService.disconnect();
  }, [authKeyPair, multiplayerId, multiplayerService]);

  const startStreamAfterCountdown = useCallback(() => {
    if (streamData.state === StreamState.InitialCountdown) {
      setStreamData({
        state: StreamState.InProgress,
        advance: streamData.advance,
      });
    } else {
      // TODO: sentry: we should not call this function from state other than INITIAL_COUNTDOWN
    }
  }, [streamData]);

  return {
    streamData,
    startStreamAfterCountdown,
    participantsByPlayerId,
    viewsCount,
    largeVideoPlayerId,
    multiPlayerSocket: multiplayerService.getSocket(),
    isStreamFinished,
    episodeContent,
    currentEpisodeId,
  };
}
