import { isMobile } from '../PageLayout/isMobile';

const getAspectRatio = (width: number, height: number) => {
  const minAspectRatio = 16 / 9;
  const maxAspectRatio = 19.5 / 9;
  const aspectRatio = height / width;
  if (aspectRatio > maxAspectRatio) {
    return maxAspectRatio;
  }
  if (aspectRatio < minAspectRatio) {
    return minAspectRatio;
  }
  return aspectRatio;
};

const heightPadding = 50;
const borderWidth = 10;

export const isAdditionalInfoShow = window.innerWidth > 1200;

export function getSizeConfig() {
  const isFeaturedStream = window.location.pathname.includes('/featured-streams/');

  const windowWidth = window.innerWidth - borderWidth;
  const windowHeight = window.innerHeight - heightPadding;
  const aspectRatio = isFeaturedStream ? 2.10 : getAspectRatio(windowWidth, windowHeight);

  const maxWidth = windowHeight / aspectRatio;
  const maxHeight = windowWidth * aspectRatio;

  const width = windowWidth > maxWidth ? maxWidth : windowWidth;
  const height = windowHeight > maxHeight ? maxHeight : windowHeight;

  const gameplayPaddings = isMobile || isAdditionalInfoShow ? 0 : heightPadding; // padding + border
  const gameplayWidth = width - gameplayPaddings;
  const gameplayHeight = height - (gameplayPaddings * aspectRatio);

  document.documentElement.style.setProperty('--gameplay-width', `${Math.round(gameplayWidth)}px`);
  document.documentElement.style.setProperty('--gameplay-height', `${Math.round(gameplayHeight)}px`);
  return isMobile ? {
    height: window.innerHeight,
    width: window.innerWidth,
    gameplayHeight,
    gameplayWidth,
  } : {
    height,
    width,
    gameplayHeight,
    gameplayWidth,
  };
}

// TODO: Move to utils or rename this file
export const setBodyColor = (color: string) => {
  document.body.style.backgroundColor = color;
};
