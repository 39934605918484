import { Spinner } from '../../../../components/Spinner/Spinner';
import { useEpisodeInfo } from '../../../../services/s3EngineObjectsCache';
import { getCoverUrl } from '../../../../utils/getCoverUrl';
import cs from './WaitingPanelV2.module.css';

export interface WaitingPanelV2Props {
    episodeId: string
    message: string
}

export function WaitingPanelV2(props: WaitingPanelV2Props) {
  const { episodeId, message } = props;
  const episodeInfo = useEpisodeInfo(episodeId);

  return (
    <div className={cs.container}>
      <img
        className={cs.imageContainer}
        height="100%"
        src={getCoverUrl(episodeInfo?.bookCover)}
        alt="cover"
      />
      <div className={cs.messageContainer}>
        <p>{message}</p>
      </div>
      <Spinner className={cs.spinner} />
    </div>
  );
}
