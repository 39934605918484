import { Link } from '@mui/material';
import CloseIcon from '../../../../icons/CloseIcon.svg';
import DownloadIcon from '../../../../icons/DownloadCircleIcon.svg';
import { DOWNLOAD_APP_URL } from '../../../../services/staticUrls';
import cs from './PopupDownload.module.css';
import { usePopupDownload } from './PopupDownloadProvider';

export function PopupDownload() {
  const { hidePopup } = usePopupDownload();

  return (
    <div className={cs.overlay}>
      <div className={cs.popup} onClick={hidePopup} role="presentation">
        <button onClick={hidePopup} type="button" className={cs.closeButton}>
          <img src={CloseIcon} alt="Close" />
        </button>
        <div className={cs.title}>Download the Dorian App to chat, send hearts and much more!</div>
        <Link className={cs.downloadButton} target="_blank" href={DOWNLOAD_APP_URL}>
          <div>
            Download the App!
          </div>
          <img src={DownloadIcon} alt="Download" />
        </Link>
      </div>
    </div>
  );
}
