import React, {
  PropsWithChildren, createContext, useContext, useState,
} from 'react';
import { PopupDownload } from './PopupDownload';

interface PopupDownloadContext {
    showPopup: () => void,
    hidePopup: () => void,
}

const popupDownloadContext = createContext<PopupDownloadContext>({
  showPopup: () => {
    throw new Error('Popup context used before initialization');
  },
  hidePopup: () => {
    throw new Error('Popup context used before initialization');
  },
});
const PopupDownloadContextProvider = popupDownloadContext.Provider;

export function PopupDownloadProvider(props: PropsWithChildren) {
  const { children } = props;

  const [popupIsVisible, setPopupIsVisible] = useState<boolean>(false);
  return (
    <PopupDownloadContextProvider value={{
      showPopup: () => setPopupIsVisible(true),
      hidePopup: () => setPopupIsVisible(false),
    }}
    >
      {children}
      {popupIsVisible && <PopupDownload />}
    </PopupDownloadContextProvider>
  );
}

export const usePopupDownload = () => useContext(popupDownloadContext);
