import axios from 'axios';
import { RuntimeEnv, getRuntimeEnv } from '../getRuntimeEnv';

enum MultiplayerServerEnvs {
  Local = 'Local',
  Unstable1 = 'Unstable1',
  Unstable2 = 'Unstable2',
  Staging = 'Staging',
  Tactical = 'Tactical',
  Prod = 'Prod',
}
const serverMultiplayerUrlByEnvLabel = {
  [MultiplayerServerEnvs.Local]: 'http://localhost:3003',
  [MultiplayerServerEnvs.Unstable1]: 'http://ec2-18-224-212-245.us-east-2.compute.amazonaws.com:3003',
  [MultiplayerServerEnvs.Unstable2]: 'http://ec2-18-222-255-227.us-east-2.compute.amazonaws.com:3003',
  [MultiplayerServerEnvs.Staging]: 'https://mp.dev.test.4dorian.net',
  [MultiplayerServerEnvs.Tactical]: 'http://ec2-18-216-176-218.us-east-2.compute.amazonaws.com:3003',
  [MultiplayerServerEnvs.Prod]: 'https://mp.live.4dorian.net',
};

const runtimeEnv = getRuntimeEnv();
const envLabelByRuntimeEnv = {
  [RuntimeEnv.Prod]: MultiplayerServerEnvs.Prod,
  [RuntimeEnv.Stg]: MultiplayerServerEnvs.Staging,
  [RuntimeEnv.Dev]: MultiplayerServerEnvs.Staging,
  [RuntimeEnv.Local]: MultiplayerServerEnvs.Staging,
};
const envLabel = envLabelByRuntimeEnv[runtimeEnv];
console.log('mp', envLabel);

export const serverMultiplayerUrl = serverMultiplayerUrlByEnvLabel[envLabel];

export const multiplayerServer = axios.create({
  baseURL: `${serverMultiplayerUrl}/v5`,
});
