import { useEffect, useState } from 'react';
import { useGlobalStateContext } from '../../../providers/GlobalStateProvider';
import cs from './SwitchStreamConfirmationPopup.module.css';

const maxCountdownValue = 10; // how many seconds before transition to another running stream

export function SwitchStreamConfirmationPopup() {
  const [switchStreamCountdown, setSwitchStreamCountdown] = useState<number>(maxCountdownValue);
  const globalState = useGlobalStateContext();

  const onClickPopup = () => {
    globalState?.setIsSwitchPopupClicked(true);
    globalState?.setIsSwitchPopupVisible(false);
  };

  useEffect(() => {
    if (switchStreamCountdown <= 0 && globalState && globalState.isSwitchPopupVisible) {
      globalState.setIsSwitchCountdownFinished(true);
      globalState.setIsSwitchPopupVisible(false);
      setSwitchStreamCountdown(maxCountdownValue);
    }
  }, [switchStreamCountdown, globalState]);

  useEffect(() => {
    if (!globalState?.isSwitchPopupVisible) {
      return () => undefined;
    }
    setSwitchStreamCountdown(maxCountdownValue);
    const countdownInterval = setInterval(() => {
      setSwitchStreamCountdown((current) => {
        const newCount = current - 1;

        return newCount;
      });
    }, 1000);

    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
  }, [globalState, globalState?.isSwitchPopupVisible]);

  return (
    <div>
      {globalState?.isSwitchPopupVisible
      && (
      <button
        type="button"
        className={`${cs.wrapper} ${globalState?.isSwitchPopupVisible ? cs.wrapperShow : cs.wrapperHide}`}
        onClick={onClickPopup}
      >
        <div className={cs.top}>
          <div className={cs.title}>Next Stream Starting in...</div>
          <div className={cs.counter}>{switchStreamCountdown}</div>
        </div>
        <div className={cs.subtitle}>Or if you want to stay with this stream, just tap it!</div>
      </button>
      )}
    </div>
  );
}
