import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function SoundOnIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <g clipPath="url(#clip0_1059_2328)">
        <path d="M8.98 7.45H1.78C1.34922 7.45 1 7.79922 1 8.23V15.43C1 15.8608 1.34922 16.21 1.78 16.21H8.98C9.41078 16.21 9.76 15.8608 9.76 15.43V8.23C9.76 7.79922 9.41078 7.45 8.98 7.45Z" fill="white" />
        <path d="M12.74 22.68L3.95001 11.84L12.74 1V22.68Z" fill="white" />
        <path d="M18.82 11.83C18.82 14.62 17.64 17.07 15.75 18.73L16.38 19.95C18.8 18.08 20.34 15.16 20.34 11.83C20.34 8.5 18.81 5.59 16.39 3.71L15.76 4.93C17.64 6.59 18.82 9.04 18.82 11.82V11.83Z" fill="white" />
        <path d="M15.77 11.83C15.77 13.46 15.25 14.95 14.39 16.11L15.08 17.44C16.45 16.01 17.29 14.02 17.29 11.83C17.29 9.64 16.44 7.66 15.08 6.23L14.39 7.56C15.25 8.72 15.77 10.21 15.77 11.83Z" fill="white" />
        <path d="M17.61 1.34L17 2.52C19.96 4.6 21.86 7.96 21.86 11.83C21.86 15.7 19.96 19.06 16.99 21.14L17.6 22.32C21.11 20.04 23.37 16.23 23.37 11.82C23.37 7.41 21.12 3.62 17.61 1.34Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1059_2328">
          <rect width="22.38" height="21.68" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
      {/* </svg> */}
    </SvgIcon>
  );
}
