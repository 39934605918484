import { LiveNowIcon } from '../../../../../../icons/LiveNowIcon';
import cs from './LiveNow.module.css';

export function LiveNow() {
  return (
    <div className={cs.wrapper}>
      <LiveNowIcon className={cs.icon} sx={{ fontSize: '0.8em' }} />
      <div className={cs.title}>Live now</div>
    </div>
  );
}
