import { Box, Link } from '@mui/material';
import React from 'react';
import liveIcon from '../../icons/liveIcon.svg';
import cs from './StreamInfoSectionV2.module.css';

const avatarSize = 46;
interface StreamInfoSectionProps {
    eventTimeTitle?: string
    eventTitle: string
    hostName: string
    hostAvatarUrl: string
    viewsCount?: number
    isShowMoreStreamsLink?: boolean
}

export function StreamInfoSectionV2(props: StreamInfoSectionProps) {
  const {
    eventTimeTitle,
    eventTitle,
    hostName,
    hostAvatarUrl,
    viewsCount,
    isShowMoreStreamsLink,
  } = props;

  return (
    <div className={cs.StreamInfoSectionV2}>
      {eventTimeTitle
        ? (
          <div className={cs.eventTimeTitle}>
            {eventTimeTitle}
          </div>
        )
        : (
          <div className={cs.eventLive}>
            <img src={liveIcon} alt="live" className={cs.liveIndicator} />
            <div
              className={cs.eventLiveTitle}
            >
              Live Now
            </div>
          </div>
        )}
      <div className={cs.eventTitle}>
        {eventTitle}
      </div>
      {hostName && (
        <div className={cs.hostLeft}>
          <div className={cs.text}>
            Hosted by:
          </div>
          <Box
            component="img"
            src={hostAvatarUrl}
            alt="avatar"
            width={avatarSize}
            height={avatarSize}
            className={cs.avatarImage}
            borderRadius="100%"
          />
          <div
            className={cs.hostName}
          >
            {hostName}
          </div>
        </div>
      )}
      {viewsCount && (
        <div className={cs.viewsCount}>
          {`${viewsCount} Playing`}
        </div>
      )}
      {isShowMoreStreamsLink && (
      <div className={cs.seeMoreStreams}>
        <Link
          href="https://home.dorian.live/stream-schedule"
          variant="h2AsLink"
          color="inherit"
        >
          See More Streams!
        </Link>
      </div>
      )}
    </div>
  );
}
