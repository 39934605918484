import { io } from 'socket.io-client';
import { Advance } from '../../dorian-shared/types/stream-socket/Advance';
import { LeavingSocketMessage } from '../../dorian-shared/types/stream-socket/LeavingSocketMessage';
import { MultiplayerStartedMessage } from '../../dorian-shared/types/stream-socket/MultiplayerStartedMessage';
import { NewHostSocketMessage } from '../../dorian-shared/types/stream-socket/NewHostSocketMessage';
import { ParticipantsSocketMessage } from '../../dorian-shared/types/stream-socket/ParticipantsSocketMessage';
import { SetLargeVideoSocketMessage } from '../../dorian-shared/types/stream-socket/SetLargeVideoSocketMessage';
import { SetNextEpisodeSocketMessage } from '../../dorian-shared/types/stream-socket/SetNextEpisodeSocketMessage';
import {
  SomeoneConnectedToRoomSocketMessage,
} from '../../dorian-shared/types/stream-socket/SomeoneConnectedToRoomSocketMessage';
import { VoteEndSocketMessage } from '../../dorian-shared/types/stream-socket/VoteEndSocketMessage';
import { StreamChatSingleMessageResult } from '../../pages/ActiveStreamPage/SendbirdChat/SendbirdChat';
import { AuthKeyPair } from '../../providers/AuthProvider';
import { logger } from '../loggerService/loggerService';
import { serverMultiplayerUrl } from '../multiplayerServer';

export enum MultiplayerEvent {
  MultiplayerStarted= 'multiplayerStarted',
  Advance = 'advance',
  NewHost = 'newHost',
  ConnectedToRoom = 'connectedToRoom',
  Participants = 'participants',
  Leaving = 'leaving',
  SomeoneConnectedToRoom = 'someoneConnectedToRoom',
  ChangeBroadcastersSet = 'changeBroadcastersSet',
  SetNextEpisode = 'setNextEpisode',
  ShutDown = 'shutDown',
  VoteStart = 'voteStart',
  VoteEnd = 'voteEnd',
  SetLargeVideo = 'setLargeVideo',
  LiveChatMessageUpdate = 'liveChatMessageUpdate',
}

class MultiplayerService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  observer: Map<MultiplayerEvent, Array<(data: any)=>void>> = new Map();

  private service;

  isJoined = false;

  isOn = false;

  constructor() {
    this.service = io({ reconnection: false });
  }

  get isConnected() {
    return this.service.connected;
  }

  connect() {
    if (this.service.connected) {
      return;
    }
    this.service = io(serverMultiplayerUrl, {
      transports: ['websocket', 'polling', 'flashsocket'],
      upgrade: false,
      closeOnBeforeunload: false, // in order to have time to send leave event into socket
    });
    logger.info('[Multiplayer] connected');
  }

  join(multiplayerId: number, authKeyPair: AuthKeyPair, appId?: string) {
    if (this.isJoined) {
      return;
    }
    this.service.emit('v5/joinWeb', {
      multiplayerId,
      appId,
      ...authKeyPair,
    });
    this.isJoined = true;
    logger.info('[Multiplayer] join', multiplayerId);
  }

  leaveWeb(multiplayerId: number, authKeyPair: AuthKeyPair) {
    this.service.emit('v5/leaveWeb', {
      multiplayerId,
      ...authKeyPair,
    });
    this.isJoined = false;
    logger.info('[Multiplayer] leaveWeb');
  }

  disconnect() {
    this.service.close();
    this.isOn = false;
    logger.info('[Multiplayer] disconnected');
  }

  on(multiplayerId: number, authKeyPair: AuthKeyPair) {
    if (this.isOn) {
      return;
    }
    logger.info('[Multiplayer] ON event');

    this.service.on(MultiplayerEvent.MultiplayerStarted, (message: MultiplayerStartedMessage) => {
      this.invokeListeners(MultiplayerEvent.MultiplayerStarted, message);
    });

    this.service.on(MultiplayerEvent.Advance, (data: Advance) => {
      this.invokeListeners(MultiplayerEvent.Advance, data);
    });

    this.service.on(MultiplayerEvent.NewHost, (data: NewHostSocketMessage) => {
      this.invokeListeners(MultiplayerEvent.NewHost, data);
    });

    this.service.on(MultiplayerEvent.ConnectedToRoom, (data) => {
      this.service.emit('v5/getParticipantsWeb', {
        multiplayerId,
        ...authKeyPair,
      });
      this.invokeListeners(MultiplayerEvent.ConnectedToRoom, data);
    });

    this.service.on(MultiplayerEvent.Participants, (data: ParticipantsSocketMessage) => {
      this.invokeListeners(MultiplayerEvent.Participants, data);
    });

    this.service.on(MultiplayerEvent.Leaving, (data: LeavingSocketMessage) => {
      this.invokeListeners(MultiplayerEvent.Leaving, data);
    });
    this.service.on(MultiplayerEvent.SomeoneConnectedToRoom, (data: SomeoneConnectedToRoomSocketMessage) => {
      this.invokeListeners(MultiplayerEvent.SomeoneConnectedToRoom, data);
    });

    this.service.on(MultiplayerEvent.ChangeBroadcastersSet, (data) => {
      this.service.emit('v5/getParticipantsWeb', {
        multiplayerId,
        ...authKeyPair,
      });
      this.invokeListeners(MultiplayerEvent.ChangeBroadcastersSet, data);
    });

    this.service.on(MultiplayerEvent.SetNextEpisode, (data: SetNextEpisodeSocketMessage) => {
      this.invokeListeners(MultiplayerEvent.SetNextEpisode, data);
    });

    this.service.on(MultiplayerEvent.ShutDown, (data) => {
      this.invokeListeners(MultiplayerEvent.ShutDown, data);
    });

    this.service.on(MultiplayerEvent.VoteStart, (data) => {
      this.invokeListeners(MultiplayerEvent.VoteStart, data);
    });

    this.service.on(MultiplayerEvent.VoteEnd, (data: VoteEndSocketMessage) => {
      this.invokeListeners(MultiplayerEvent.VoteEnd, data);
    });

    this.service.on(MultiplayerEvent.SetLargeVideo, (data: SetLargeVideoSocketMessage) => {
      this.invokeListeners(MultiplayerEvent.SetLargeVideo, data);
    });

    this.service.on(MultiplayerEvent.LiveChatMessageUpdate, (data: StreamChatSingleMessageResult) => {
      this.invokeListeners(MultiplayerEvent.LiveChatMessageUpdate, data);
    });

    this.isOn = true;
  }

  heartbeatStartWeb(multiplayerId: number, authKeyPair: AuthKeyPair, heartbeats: number) {
    this.service.emit('v5/heartbeatWeb', {
      ...authKeyPair,
      heartbeats,
      multiplayerId,
      videoCount: 1,
    });
    logger.info('[Multiplayer] heartbeatStartWeb', heartbeats);
  }

  heartbeatStopWeb(multiplayerId: number, authKeyPair: AuthKeyPair, heartbeats: number) {
    this.service.emit('v5/heartbeatStopWeb', {
      ...authKeyPair,
      heartbeats,
      multiplayerId,
      videoCount: 1,
    });
    logger.info('[Multiplayer] heartbeatStopWeb', heartbeats);
  }

  // Observer
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addListener(multiplayerEvent: MultiplayerEvent, callback: (data: any) => void) {
    const listeners = this.observer.get(multiplayerEvent) ?? [];
    if (!listeners.includes(callback)) {
      this.observer.set(multiplayerEvent, [...listeners, callback]);
      logger.info('[Multiplayer] ADD listener', multiplayerEvent);
    }
    return callback;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeListener(multiplayerEvent: MultiplayerEvent, callback: (data: any) => void) {
    const listeners = this.observer.get(multiplayerEvent);
    if (listeners) {
      if (listeners.includes(callback)) {
        const newListeners = listeners.filter((listener) => {
          if (listener !== callback) {
            return true;
          }
          logger.info('[Multiplayer] REMOVE listener', multiplayerEvent);
          return false;
        });
        this.observer.set(multiplayerEvent, newListeners ?? []);
      }
    }
  }

  getSocket() {
    return this.service;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invokeListeners(multiplayerEvent: MultiplayerEvent, data: any) {
    const listeners = this.observer.get(multiplayerEvent);
    listeners?.forEach((listener) => {
      listener?.(data);
    });
    logger.info('[Multiplayer] INVOKE', multiplayerEvent, listeners?.length);
  }
}

const multiplayerService = new MultiplayerService();

export { multiplayerService };
