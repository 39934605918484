import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { StreamManager } from './components/StreamManager/StreamManager';
import { useForceReload } from './dorian-shared/hooks/useForceReload';
import { isMobile } from './PageLayout/isMobile';
import { PageLayout } from './PageLayout/PageLayout';
import { PageLayoutV2 } from './PageLayout/PageLayoutV2';
import { ActiveStreamPage } from './pages/ActiveStreamPage/ActiveStreamPage';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
import { FeaturedStreamPage } from './pages/FeaturedStreamPage/FeaturedStreamPage';
import { RedirectStream } from './pages/RedirectStream/RedirectStream';
import { ScheduledStreamPage } from './pages/ScheduledStreamPage/ScheduledStreamPage';
import { scheduledStreamPageLoader } from './pages/ScheduledStreamPage/scheduledStreamPageLoader';
import { AuthProvider } from './providers/AuthProvider';
import { GlobalStateProvider } from './providers/GlobalStateProvider';
import './providers/theme.css';
import { ThemeProvider } from './providers/ThemeProvider';
import { ToastProvider } from './providers/ToastProvider';

// it is important to call createBrowserRouter inside this component,
// otherwise route loaders are rendered before user is authenticated
function Router() {
  const router = useMemo(() => createBrowserRouter([
    {
      path: '/v0/',
      element: <PageLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'streams/:multiplayerId',
          element: <ActiveStreamPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'streams/:multiplayerId/:isFeaturedView',
          element: <ActiveStreamPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'scheduled-streams/:scheduledStreamId',
          element: <ScheduledStreamPage />,
          loader: scheduledStreamPageLoader,
          errorElement: <ErrorPage />,
        },
      ],
    },
    {
      path: '/',
      element: <PageLayoutV2 />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'streams/:multiplayerId',
          element: <RedirectStream />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'streams/:multiplayerId/:isFeaturedView',
          element: <RedirectStream />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'scheduled-streams/:scheduledStreamId',
          element: <RedirectStream />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'featured-streams/',
          element: <FeaturedStreamPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'stream/',
          element: <StreamManager />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]), []);

  return <RouterProvider router={router} />;
}

export function App() {
  const { forceReloadKey } = useForceReload(isMobile);
  return (
    <AuthProvider>
      <ThemeProvider>
        <StyledEngineProvider injectFirst>
          <ToastProvider>
            <GlobalStateProvider key={forceReloadKey}>
              <CssBaseline />
              <Router />
            </GlobalStateProvider>
          </ToastProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}
