import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function LiveNowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <circle cx="11" cy="11" r="11" fill="#F56969" fillOpacity="0.4" />
      <circle cx="11" cy="11" r="6.76923" fill="#F56969" fillOpacity="0.4" />
      <circle cx="11" cy="11" r="2.53846" fill="#F56969" />
    </SvgIcon>
  );
}
