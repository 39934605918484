import clsx from 'clsx';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
// import { DownloadAppSection } from '../../../components/DownloadAppSection/DownloadAppSection';
import { DownloadDorianBottomBanner } from '../../../components/DownloadDorianBottomBanner/DownloadDorianBottomBanner';
import { SwitchStreamConfirmationPopup } from '../../FeaturedStreamPage/SwitchStreamConfirmationPopup/SwitchStreamConfirmationPopup';
import { BaseParticipant } from '../types/BaseParticipant';
import { StreamState } from '../types/StreamState';
import { ErrorBoundary } from './Gameplay/ErrorBoundary/ErrorBoundary';
import { Gameplay } from './Gameplay/Gameplay';
import { useChoiceMemoryVariablesValuesByName } from './hooks/useChoiceMemoryVariablesValuesByName';
import { useVideosByPlayerIdV2 } from './hooks/useVideosByPlayerIdV2';
import { Participants } from './Participants/Participants';
import cs from './Stream.module.css';
import { StreamFooter } from './StreamFooter/StreamFooter';
import { StreamHeader } from './StreamHeader/StreamHeader';
import { StreamStartCountdown } from './StreamStartCountdown/StreamStartCountdown';
import { StreamStatus } from './StreamStatus/StreamStatus';
import { sortParticipants } from './streamUtils';
import { useStreamDataV2 } from './useStreamDataV2';
import { VotingCountdown } from './VotingCountdown/VotingCountdown';
import { WaitingPanelV2 } from './WaitingPanelV2/WaitingPanelV2';

interface StreamPropsV2 {
  gameplayWidth: number
  gameplayHeight: number
  episodeId: string
  setEpisodeId: (episodeId: string, source?: string) => void
  multiplayerId: number
  eventTitle?: string
  isFeaturedView?: boolean
  isStreamExist?: boolean
}

export function StreamV2(props: StreamPropsV2) {
  const {
    gameplayWidth, gameplayHeight, episodeId, multiplayerId, eventTitle, isFeaturedView, isStreamExist, setEpisodeId,
  } = props;

  const {
    streamData,
    startStreamAfterCountdown,
    participantsByPlayerId,
    viewsCount,
    largeVideoPlayerId,
    episodeContent,
    // currentEpisodeId,
  } = useStreamDataV2(multiplayerId, episodeId, setEpisodeId, isStreamExist);

  const {
    videosByPlayerId,
  } = useVideosByPlayerIdV2(multiplayerId);

  const isLargeVideoOn = Boolean(largeVideoPlayerId);

  const [searchParams] = useSearchParams();
  const isFeaturedStream = isFeaturedView || Boolean(searchParams.get('isFeaturedView'));

  const hostPlayer = useMemo(
    () => Object
      .values(participantsByPlayerId)
      .find((participant: BaseParticipant) => participant.host),
    [participantsByPlayerId],
  );
  const hostName = hostPlayer?.username ?? '';
  const hostAvatarUrl = hostPlayer?.avatarUrl ?? '';

  const visibleParticipants = useMemo(
    () => Object
      .values(participantsByPlayerId)
      .filter((participant) => participant.canBroadcast)
      .sort(sortParticipants)
      .slice(0, 5),
    [participantsByPlayerId],
  );

  const choiceMemoryVariablesValuesByName = useChoiceMemoryVariablesValuesByName(
    episodeId, // currentEpisodeId,
    streamData.state === StreamState.InProgress ? streamData.advance.variables : {},
  );

  return (
    <>
      {streamData.state === StreamState.InProgress && (
        <ErrorBoundary>
          <div className={clsx({ [cs.invisible]: isLargeVideoOn })}>
            <Gameplay
              // episodeUuid={currentEpisodeId}
              episodeUuid={episodeId}
              episodeContent={episodeContent}
              branchName={streamData.advance.branch}
              stepIndex={streamData.advance.index}
              width={gameplayWidth}
              height={gameplayHeight}
              playerName={hostName} // temp requirements till we have authentication
              choiceMemoryVariablesValuesByName={choiceMemoryVariablesValuesByName}
            />
          </div>
          {/* {!isChatVisible && <DownloadAppSection />} */}
        </ErrorBoundary>
      )}
      <div className={cs.streamControlsContainer} style={{ width: gameplayWidth, height: gameplayHeight }}>
        <StreamHeader
          isStreamExist={isStreamExist}
        >
          <StreamStatus
            viewsCount={viewsCount}
            mainTitle={hostName}
            hostAvatarUrl={hostAvatarUrl}
            subTitle={eventTitle}
            isTitleReversed={false}
            isIconsVisible
          />
        </StreamHeader>
        <Participants
          videosByPlayerId={videosByPlayerId}
          participants={visibleParticipants}
          largeVideoPlayerId={largeVideoPlayerId}
        />
        <VotingCountdown />
        <div id="LargeVideoPortalContainer" className={cs.largeVideoContainer}>
          {!isLargeVideoOn && (
            <>
              {streamData.state === StreamState.InitialCountdown && (
                <StreamStartCountdown
                  onCountdownEnd={() => setTimeout(startStreamAfterCountdown, 1000)}
                />
              )}
              {streamData.state === StreamState.PreStreamLobby && (
              <WaitingPanelV2 episodeId={episodeId} message="Waiting for others to join..." />
              )}
              {streamData.state === StreamState.AfterEpisodeLobby && (
              <WaitingPanelV2 episodeId={episodeId} message="Choosing another episode..." />
              )}
            </>
          )}
        </div>
        <DownloadDorianBottomBanner />
        {isStreamExist && !isFeaturedStream
          && (
            <StreamFooter />
            // handleChatIconClick={handleChatIconClick}
          )}
        {isFeaturedStream
        && (
        <SwitchStreamConfirmationPopup />
        )}
      </div>
    </>
  );
}
