import clsx from 'clsx';
import { isMobile } from '../../../../PageLayout/isMobile';
import cs from './StreamStatus.module.css';
import { StreamStatusIcons } from './StreamStatusIcons/StreamStatusIcons';

export interface StreamStatusProps {
    viewsCount: number,
    hostAvatarUrl?: string,
    mainTitle?: string,
    subTitle?: string,
    isTitleReversed?: boolean,
    isIconsVisible?: boolean,
}
export function StreamStatus(props: StreamStatusProps) {
  const {
    viewsCount, mainTitle, hostAvatarUrl, subTitle, isTitleReversed,
    isIconsVisible,
  } = props;

  return (
    <div
      className={clsx(cs.container, {
        [cs.mobile]: isMobile,
      })}
    >
      {hostAvatarUrl && (
      <div className={cs.hostPictureWrapper}>
        <img src={hostAvatarUrl} alt="Avatar" />
      </div>
      )}
      <div className={clsx(cs.titleWrapper, {
        [cs.titleWrapperReversed]: isTitleReversed,
      })}
      >
        <div className={cs.mainTitle}>{mainTitle}</div>
        <div className={cs.subTitle}>{subTitle}</div>
      </div>
      {isIconsVisible && <StreamStatusIcons viewsCount={viewsCount} />}
    </div>
  );
}
