import clsx from 'clsx';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalStateContext } from '../../../providers/GlobalStateProvider';
import { BaseParticipant } from '../types/BaseParticipant';
import { StreamState } from '../types/StreamState';
import { ErrorBoundary } from './Gameplay/ErrorBoundary/ErrorBoundary';
import { Gameplay } from './Gameplay/Gameplay';
import { useChoiceMemoryVariablesValuesByName } from './hooks/useChoiceMemoryVariablesValuesByName';
import { useVideosByPlayerId } from './hooks/useVideosByPlayerId';
import { HostLeftDialog } from './HostLeftDialog/HostLeftDialog';
import { Participants } from './Participants/Participants';
import cs from './Stream.module.css';
import { StreamFooter } from './StreamFooter/StreamFooter';
import { StreamHeader } from './StreamHeader/StreamHeader';
import { StreamIsOver } from './StreamIsOver/StreamIsOver';
import { StreamStartCountdown } from './StreamStartCountdown/StreamStartCountdown';
import { StreamStatus } from './StreamStatus/StreamStatus';
import { sortParticipants } from './streamUtils';
import { useStreamData } from './useStreamData';
import { VotingCountdown } from './VotingCountdown/VotingCountdown';
import { WaitingPanel } from './WaitingPanel/WaitingPanel';

interface StreamProps {
  gameplayWidth: number
  gameplayHeight: number
  episodeId: string
  isFeaturedStream: boolean
}

export function Stream(props: StreamProps) {
  const {
    gameplayWidth, gameplayHeight, episodeId, isFeaturedStream,
  } = props;

  const {
    streamData,
    startStreamAfterCountdown,
    participantsByPlayerId,
    viewsCount,
    largeVideoPlayerId,
    isStreamFinished,
    episodeContent,
    currentEpisodeId,
  } = useStreamData(episodeId);

  const {
    videosByPlayerId,
  } = useVideosByPlayerId(isStreamFinished);

  const isLargeVideoOn = Boolean(largeVideoPlayerId);

  const hostName = useMemo(
    () => Object
      .values(participantsByPlayerId)
      .find((participant: BaseParticipant) => participant.host)?.username ?? '',
    [participantsByPlayerId],
  );
  const visibleParticipants = useMemo(
    () => Object
      .values(participantsByPlayerId)
      .filter((participant) => participant.canBroadcast)
      .sort(sortParticipants)
      .slice(0, 5),
    [participantsByPlayerId],
  );

  const navigate = useNavigate();
  const globalState = useGlobalStateContext();

  const navigateToFeaturedStreams = useCallback(() => {
    navigate('/featured-streams/', { replace: true });
  }, [navigate]);

  const onDataError = useCallback(() => {
    // globalState.increaseFeaturedStreamsReloadCount();
    globalState?.setFeaturedStreamsReloadCount(globalState.featuredStreamsReloadCount + 1);
    if (isFeaturedStream) {
      navigateToFeaturedStreams();
    }
  }, [isFeaturedStream, navigateToFeaturedStreams, globalState]);

  const choiceMemoryVariablesValuesByName = useChoiceMemoryVariablesValuesByName(
    currentEpisodeId,
    streamData.state === StreamState.InProgress ? streamData.advance.variables : {},
    onDataError,
  );

  useEffect(() => {
    if (!isFeaturedStream) {
      return;
    }
    if (streamData.state !== StreamState.StreamIsOver) {
      return;
    }

    navigateToFeaturedStreams();
  }, [streamData.state, isFeaturedStream, navigateToFeaturedStreams]);

  return (
    <>
      {streamData.state === StreamState.InProgress && (
        <ErrorBoundary>
          <div className={clsx({ [cs.invisible]: isLargeVideoOn })}>
            <Gameplay
              episodeUuid={currentEpisodeId}
              episodeContent={episodeContent}
              branchName={streamData.advance.branch}
              stepIndex={streamData.advance.index}
              width={gameplayWidth}
              height={gameplayHeight}
              playerName={hostName} // temp requirements till we have authentication
              choiceMemoryVariablesValuesByName={choiceMemoryVariablesValuesByName}
            />
          </div>
        </ErrorBoundary>
      )}
      <div className={cs.streamControlsContainer} style={{ width: gameplayWidth, height: gameplayHeight }}>
        <StreamHeader>
          <StreamStatus
            viewsCount={viewsCount}
          />
        </StreamHeader>
        <Participants
          videosByPlayerId={videosByPlayerId}
          participants={visibleParticipants}
          largeVideoPlayerId={largeVideoPlayerId}
        />
        <VotingCountdown />
        {streamData.state === StreamState.HostLeftStream && <HostLeftDialog open />}
        <div id="LargeVideoPortalContainer" className={cs.largeVideoContainer}>
          {!isLargeVideoOn && (
            <>
              {streamData.state === StreamState.InitialCountdown && (
              <StreamStartCountdown
                onCountdownEnd={() => setTimeout(startStreamAfterCountdown, 1000)}
              />
              )}
              {streamData.state === StreamState.PreStreamLobby && <WaitingPanel /> }
              {streamData.state === StreamState.AfterEpisodeLobby && <WaitingPanel />}
              {streamData.state === StreamState.StreamIsOver && <StreamIsOver />}
            </>
          )}
        </div>
        <StreamFooter />
      </div>
    </>
  );
}
