import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function SoundOIffIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <g clipPath="url(#clip0_1059_2335)">
        <path d="M2.21997 1.62L22.86 22.26" stroke="white" strokeWidth="1.75" strokeMiterlimit="10" />
        <path d="M1.78 7.65C1.35 7.65 1 8 1 8.43V15.62C1 16.05 1.35 16.4 1.78 16.4H7.49L12.74 22.87V13.31L7.07 7.64H1.78V7.65Z" fill="white" />
        <path d="M12.74 1.19L9.59003 5.07L12.74 8.22V1.19Z" fill="white" />
        <path d="M20.34 12.03C20.34 8.7 18.81 5.79 16.39 3.91L15.76 5.13C17.64 6.79 18.82 9.24 18.82 12.02C18.82 12.73 18.74 13.41 18.6 14.07L19.81 15.28C20.15 14.26 20.34 13.17 20.34 12.02V12.03Z" fill="white" />
        <path d="M15.75 18.92L16.38 20.14C16.98 19.68 17.52 19.15 18 18.57L17 17.57C16.63 18.06 16.21 18.52 15.75 18.92Z" fill="white" />
        <path d="M14.39 16.3L15.08 17.63C15.4 17.29 15.69 16.92 15.96 16.52L14.91 15.47C14.75 15.76 14.59 16.04 14.4 16.29L14.39 16.3Z" fill="white" />
        <path d="M17.29 12.03C17.29 9.84 16.44 7.86 15.08 6.43L14.39 7.76C15.1 8.71 15.55 9.9 15.7 11.19L17.25 12.74C17.27 12.5 17.28 12.27 17.28 12.03H17.29Z" fill="white" />
        <path d="M23.38 12.03C23.38 7.63 21.12 3.82 17.61 1.54L17 2.72C19.96 4.8 21.86 8.16 21.86 12.03C21.86 13.61 21.53 15.09 20.96 16.44L22.07 17.55C22.9 15.89 23.39 14.02 23.39 12.03H23.38Z" fill="white" />
        <path d="M17 21.34L17.61 22.52C18.48 21.95 19.28 21.29 19.98 20.55L18.99 19.56C18.4 20.22 17.73 20.82 16.99 21.33L17 21.34Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1059_2335">
          <rect width="22.47" height="21.88" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
      {/* </svg> */}

    </SvgIcon>
  );
}
