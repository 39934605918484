import {
  PropsWithChildren, createContext, useContext, useState,
} from 'react';
import { SimpleSelectedStream } from '../pages/FeaturedStreamPage/featuredStreamUtils';

type GlobalStateContext = {
  featuredStreamsReloadCount: number;
  setFeaturedStreamsReloadCount: React.Dispatch<React.SetStateAction<number>>;

  isSwitchPopupVisible: boolean;
  setIsSwitchPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;

  isSwitchPopupClicked: boolean;
  setIsSwitchPopupClicked: React.Dispatch<React.SetStateAction<boolean>>;

  isSwitchCountdownFinished: boolean;
  setIsSwitchCountdownFinished: React.Dispatch<React.SetStateAction<boolean>>;

  nextStream: SimpleSelectedStream | null,
  setNextStream: React.Dispatch<React.SetStateAction<SimpleSelectedStream | null>>;

  appId: string | undefined,
  setAppId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const globalStateContext = createContext<GlobalStateContext | null>(null);

const { Provider } = globalStateContext;

export function GlobalStateProvider(props: PropsWithChildren) {
  const { children } = props;
  const [featuredStreamsReloadCount, setFeaturedStreamsReloadCount] = useState(0);
  const [isSwitchPopupVisible, setIsSwitchPopupVisible] = useState(false);
  const [isSwitchPopupClicked, setIsSwitchPopupClicked] = useState(false);
  const [isSwitchCountdownFinished, setIsSwitchCountdownFinished] = useState(false);
  const [nextStream, setNextStream] = useState<SimpleSelectedStream | null>(null);
  const [appId, setAppId] = useState<string | undefined>(undefined);

  return (
    <Provider value={{
      featuredStreamsReloadCount,
      setFeaturedStreamsReloadCount,
      isSwitchPopupVisible,
      setIsSwitchPopupVisible,
      isSwitchPopupClicked,
      setIsSwitchPopupClicked,
      isSwitchCountdownFinished,
      setIsSwitchCountdownFinished,
      nextStream,
      setNextStream,
      appId,
      setAppId,
    }}
    >
      {children}
    </Provider>
  );
}

export const useGlobalStateContext = () => useContext(globalStateContext);
