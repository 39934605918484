// import {
//   , format, parseISO,
// } from 'date-fns';
import {
  differenceInSeconds, parseISO,
} from 'date-fns';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import cs from './StreamPageCountdown.module.css';

type TimeDifference = {
  hours: number,
  minutes: number,
}

function getTimeDifference(secondsLeft: number): TimeDifference {
  if (secondsLeft < 0) {
    return {
      hours: 0,
      minutes: 0,
    };
  }

  const minuteInSeconds = 60;
  const hourInSeconds = minuteInSeconds * 60;

  const hours = secondsLeft > hourInSeconds ? Math.floor(secondsLeft / hourInSeconds) : 0;
  const minutes = Math.floor((secondsLeft - (hours * hourInSeconds)) / minuteInSeconds);

  return {
    hours,
    minutes,
  };
}

function formatNumber(number: number) {
  if (number <= 9) {
    return `0${number}`;
  }
  return `${number}`;
}

function getDatesDifference(startTime: string) {
  const startTimeISO = parseISO(startTime);
  const secondsDifference = differenceInSeconds(startTimeISO, new Date());
  const timeDifferenceResult = getTimeDifference(secondsDifference);

  return timeDifferenceResult;
}

export interface StreamPageCountdownProps {
  startTime?: string,
  setIsPreparing: React.Dispatch<React.SetStateAction<boolean>>,
}

export function StreamPageCountdown(props: StreamPageCountdownProps) {
  const {
    startTime, setIsPreparing,
  } = props;
  const [timeDifference, setTimeDifference] = useState<TimeDifference>({ hours: 0, minutes: 0 });
  const intervalRef = useRef<NodeJS.Timer>();

  const { hours, minutes } = timeDifference;

  const timeUpdate = useCallback(() => {
    if (!startTime) {
      return;
    }
    const timeDifferenceResult = getDatesDifference(startTime);
    setTimeDifference(timeDifferenceResult);

    if (timeDifferenceResult.hours <= 0 && timeDifferenceResult.minutes <= 0) {
      setIsPreparing(true);
    }
  }, [startTime, setIsPreparing]);

  useEffect(() => {
    timeUpdate();
    intervalRef.current = setInterval(() => {
      timeUpdate();
    }, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [timeUpdate]);
  return (
    <div className={cs.wrapper}>
      <div className={cs.title}>Stream starts in:</div>
      <div className={cs.numbersWrapper}>
        <div className={cs.numbers}>
          <div className={cs.time}>{formatNumber(hours)}</div>
          <div className={cs.timeName}>hours</div>
        </div>
        <div className={cs.numbers}>
          <div className={cs.time}>{formatNumber(minutes)}</div>
          <div className={cs.timeName}>minutes</div>
        </div>
      </div>
    </div>
  );
}
