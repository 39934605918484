import { Button, SvgIconProps } from '@mui/material';
import { SoundOIffIcon } from '../../../icons/SoundOIffIcon';
import { SoundOnIcon } from '../../../icons/SoundOnIcon';
import cs from './MuteButton.module.css';

export interface MuiMaterialProps extends SvgIconProps {
  isMuted: boolean
  onMuteChange: (value: boolean) => void
}

export function MuteButton(props: MuiMaterialProps) {
  const { isMuted, onMuteChange } = props;
  return (
    <Button onClick={() => onMuteChange(!isMuted)} className={cs.button}>
      {isMuted
        ? <SoundOIffIcon sx={{ fontSize: '.8em' }} />
        : <SoundOnIcon color="secondary" sx={{ fontSize: '.8em' }} />}
    </Button>
  );
}
