import { useEffect, useRef } from 'react';
import { getSizeConfig } from '../../../dorian-shared/getSizeConfig';
import dorianBlueLogo from '../../../icons/DorianBlueLogo.svg';
import { useStreamPageStyles } from '../../StreamPage/StreamPageStyles';
import cs from './Preloader.module.css';

type PreloaderProps = {
  isLoading: boolean;
}

export function Preloader(props: PreloaderProps) {
  const { isLoading } = props;
  const preloaderWrapperRef = useRef<HTMLDivElement>(null);
  const streamPageStyles = useStreamPageStyles();
  const { gameplayWidth, gameplayHeight } = getSizeConfig();
  useEffect(() => {
    const wrapper = preloaderWrapperRef.current;
    if (!wrapper) {
      return;
    }

    if (isLoading) {
      wrapper.classList.add(cs.preloaderVisible);
      wrapper.classList.remove(cs.preloaderHidden);
    } else {
      wrapper.classList.remove(cs.preloaderVisible);
      wrapper.classList.add(cs.preloaderHidden);
    }
  }, [isLoading]);

  return (
    <div
      className={`${streamPageStyles.streamContainerWithPaddings} ${cs.preloaderMainWrapper} ${cs.preloaderVisible}`}
      ref={preloaderWrapperRef}
    >
      <div
        className={streamPageStyles.streamContainer}
        style={{ width: gameplayWidth - 10, height: gameplayHeight }}
      >
        <div className={`${cs.wrapper} }`}>
          <img className={cs.logo} src={dorianBlueLogo} alt="Dorian Logo" />
          <p className={cs.subtitle}>Loading</p>
        </div>
      </div>
    </div>
  );
}
