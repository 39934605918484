import { parseISO } from 'date-fns';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  ScheduledEventResponseData,
  ScheduledEventStatus,
} from '../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventResponseData';
import { isDebugLogForFeaturedStreams } from '../pages/FeaturedStreamPage/FeaturedStreamPage';
import { getScheduledStreamWeb } from '../services/apiServer';

const minTimeBetweenUpdates = 2 * 60 * 1000;// every 2 minutes

export function useFetchStreamStatusByInterval(
  scheduledStreamId: number | null,
  runningStreamId?: number | null,
  onCancelStream?: (isCanceled: boolean) => void,
  onStartStream?: (eventId: number, sessionId: number) => void,
  intervalInMs: number = 10 * 1000,
  isFeaturedView = false,
) {
  const [scheduledStreamData, setScheduledStreamData] = useState<ScheduledEventResponseData>();
  const [isCanceledStream, setIsCanceledStream] = useState(false);
  const lastUpdateTime = useRef<number>(new Date().getTime());

  const setIsCanceledStreamProxy = useCallback((isCanceled: boolean) => {
    if (onCancelStream) {
      onCancelStream(isCanceled);
    }

    // prevent changing view if featured view (we change it in FeaturedStreamPage)
    if (isFeaturedView) {
      return;
    }
    setIsCanceledStream(isCanceled);
  }, [onCancelStream, isFeaturedView]);

  const setIsStartStreamProxy = useCallback((stream: ScheduledEventResponseData) => {
    if (onStartStream) {
      const { eventId, multiplayerId: sessionId } = stream;
      onStartStream(eventId, sessionId);
    }
  }, [onStartStream]);

  const getScheduledStreamWebData = useCallback(() => {
    const beforeDate = parseISO(scheduledStreamData?.timeBefore ?? '');
    const isTimeToGoLive = beforeDate <= new Date();

    const timeSinceLastUpdate = new Date().getTime() - lastUpdateTime.current;
    const isTimeToUpdate = timeSinceLastUpdate > minTimeBetweenUpdates;

    if (isDebugLogForFeaturedStreams) {
      console.log('%c ---- getScheduledStreamWebData ---', 'color: red');
      console.log('%c \tisTimeToGoLive', 'color: red', isTimeToGoLive);
      console.log('%c \tisTimeToUpdate', 'color: orange', isTimeToUpdate);
      console.log('%c \ttimeSinceLastUpdate', 'color: pink', timeSinceLastUpdate);
      console.log('%c ---- ------------------------- ---', 'color: red');
    }
    if (isTimeToGoLive || isTimeToUpdate) {
      lastUpdateTime.current = new Date().getTime();
      getScheduledStreamWeb(Number(scheduledStreamId)).then((scheduledStream) => {
        setScheduledStreamData(scheduledStream);
        if (isDebugLogForFeaturedStreams) {
          console.log(
            '%c %%%%%%%%% setScheduledStreamData %%%%%%%',
            'color: violet; background-color: pink',
            scheduledStream,
          );
        }
      }).catch(() => setIsCanceledStreamProxy(true));
    }
  }, [
    scheduledStreamData?.timeBefore, scheduledStreamId,
    setIsCanceledStreamProxy,
  ]);

  useEffect(() => {
    if (!scheduledStreamId) {
      return;
    }
    getScheduledStreamWeb(Number(scheduledStreamId)).then((scheduledStream) => {
      setScheduledStreamData(scheduledStream);
      if (isDebugLogForFeaturedStreams) {
        console.log(
          '%c %%%%%%%%% setScheduledStreamData %%%%%%%',
          'color: violet; background-color: orange',
          scheduledStream,
        );
      }
    }).catch(() => setIsCanceledStreamProxy(true));
  }, [scheduledStreamId, setIsCanceledStreamProxy]);

  useEffect(() => {
    if (!scheduledStreamId) {
      return () => undefined;
    }

    if (runningStreamId) {
      return () => undefined;
    }

    const intervalId = setInterval(() => getScheduledStreamWebData(), intervalInMs);
    const isStreamStarted = scheduledStreamData?.status !== ScheduledEventStatus.NotStarted;

    if (isDebugLogForFeaturedStreams) {
      console.log('%c isStreamStarted', 'color: blue; background-color: red', isStreamStarted);
      console.log('%c isStreamStarted', 'color: blue; background-color: red', scheduledStreamData);
    }
    if (isStreamStarted && scheduledStreamData) {
      setIsStartStreamProxy(scheduledStreamData);
    }
    if (isStreamStarted || isCanceledStream) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [getScheduledStreamWebData, intervalInMs, isCanceledStream, scheduledStreamData?.status, scheduledStreamId,
    setIsStartStreamProxy, scheduledStreamData, runningStreamId,
  ]);

  // useEffect(() => {
  //   if (runningStreamId) {
  //     setScheduledStreamData(undefined);
  //   }
  // }, [runningStreamId]);

  return {
    scheduledStreamData,
    isCanceledStream,
  };
}
