import { useEffect } from 'react';
import {
  redirect, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';

export function RedirectStream() {
  const params = useParams();
  const multiplayerId = Number(params.multiplayerId);
  const scheduledStreamId = Number(params.scheduledStreamId);
  const isFeaturedView = Boolean(params.isFeaturedView);

  const featureViewParam = isFeaturedView ? `&isFeaturedView=${isFeaturedView}` : '';
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  if (isFeaturedView) {
    searchParams.set('isFeaturedView', isFeaturedView.toString());
  }

  useEffect(() => {
    if (multiplayerId) {
      searchParams.set('multiplayerId', multiplayerId.toString());
    }
    if (scheduledStreamId) {
      searchParams.set('scheduledStreamId', scheduledStreamId.toString());
    }
    navigate(`/stream?${searchParams.toString()}`, { replace: true });
  }, [featureViewParam, multiplayerId, navigate, scheduledStreamId, searchParams]);
  redirect('home.dorian.live');
  return null;
}
