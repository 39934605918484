import React from 'react';
import { StreamPageCountdown } from './StreamPageCountdown';
import { StreamPagePreparing } from './StreamPagePreparing';

type StreamPageBottomProps = {
  isPreparing: boolean,
  startTime?: string,
  setIsPreparing: React.Dispatch<React.SetStateAction<boolean>>,
}

export function StreamPageBottom(props: StreamPageBottomProps) {
  const { isPreparing, startTime, setIsPreparing } = props;

  return (
    !isPreparing
      ? (
        <StreamPageCountdown
          startTime={startTime}
          setIsPreparing={setIsPreparing}
        />
      )
      : <StreamPagePreparing />
  );
}
