import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetDorianAppSection } from '../../components/GetDorianAppSection/GetDorianAppSection';
import { StreamInfoSectionV2 } from '../../components/StreamInfoSectionV2/StreamInfoSectionV2';
import { StreamInfoSectionV2Small } from '../../components/StreamInfoSectionV2Small/StreamInfoSectionV2Small';
import { getSizeConfig } from '../../dorian-shared/getSizeConfig';
// import { ConnectedToRoomMessage } from '../../dorian-shared/types/stream-socket/ConnectedToRoomMessage';
import { NewHostSocketMessage } from '../../dorian-shared/types/stream-socket/NewHostSocketMessage';
import { ParticipantsSocketMessage } from '../../dorian-shared/types/stream-socket/ParticipantsSocketMessage';
import { SetNextEpisodeSocketMessage } from '../../dorian-shared/types/stream-socket/SetNextEpisodeSocketMessage';
import {
  SomeoneConnectedToRoomSocketMessage,
} from '../../dorian-shared/types/stream-socket/SomeoneConnectedToRoomSocketMessage';
import { MultiplayerProvider } from '../../providers/MultiPlayerProvider';
import { MultiplayerEvent, multiplayerService } from '../../services/multiplayerService/multiplayerService';
import { useEpisodeInfo } from '../../services/s3EngineObjectsCache';
import { useStreamPageStyles } from '../StreamPage/StreamPageStyles';
// import { fetchMultiplayerStatus } from './fetchMultiplayerStatus';
// import { SendbirdChat } from './SendbirdChat/SendbirdChat';
import { PopupDownloadProvider } from './Stream/PopupDownload/PopupDownloadProvider';
import { StreamOverlay } from './Stream/StreamOverlay/StreamOverlay';
import { StreamV2 } from './Stream/StreamV2';
import { BaseParticipant } from './types/BaseParticipant';

export interface ActiveStreamPageV2Props {
  multiplayerId?: number;
  eventTitle?: string;
  isFeaturedView?: boolean;
  refreshFeaturedStreamView?: () => void;
  episodeId: string;
  setEpisodeId: (episodeId: string, source?: string) => void;
  isStreamExist?: boolean;
  setIsStreamExist: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export function ActiveStreamPageV2(props: ActiveStreamPageV2Props) {
  const {
    multiplayerId: multiplayerIdFromProps, eventTitle, isFeaturedView, refreshFeaturedStreamView,
    episodeId, setEpisodeId, setIsStreamExist, isStreamExist,
  } = props;

  const [host, setHost] = useState<BaseParticipant | undefined>();
  const [viewsCount, setViewsCount] = useState(0);
  const [eventTimeTitle, setEventTimeTitle] = useState<string | undefined>();
  // const [connectedToRoomData, setConnectedToRoomData] = useState<ConnectedToRoomMessage>();
  // const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
  // const [isNewChatMessage, setIsNewChatMessage] = useState<boolean>(false);

  const params = useParams();
  const multiplayerId = multiplayerIdFromProps ?? Number(params.multiplayerId);
  const streamPageStyles = useStreamPageStyles();

  const episodeInfo = useEpisodeInfo(episodeId ?? '');

  const isStreamIframe = window.parent !== window;

  // useEffect(() => {
  //   fetchMultiplayerStatus(multiplayerId).then((newMultiplayerStatus) => {
  //     setEpisodeId(newMultiplayerStatus.episodeId, 'ActiveStreampageV2 useEffect v1');
  //     // console.log('%c fetchMultiplayerStatus setEpisodeId', 'color: blue', newMultiplayerStatus.episodeId);
  //     setIsStreamExist(Boolean(newMultiplayerStatus.episodeId));
  //   });
  // }, [multiplayerId, setEpisodeId]);

  // useEffect(() => {
  //   // check if stream is active when we are in featured stream view
  //   // so if stream is not active, redirect user to other stream|scheduledStream
  //   let fetchInterval: ReturnType<typeof setTimeout> | null = null;
  //   if (isFeaturedView && refreshFeaturedStreamView) {
  //     const fetchTime = 3 * 60 * 1000;
  //     fetchInterval = setInterval(() => {
  //       fetchMultiplayerStatus(multiplayerId).then((newMultiplayerStatus) => {
  //         const isStreamExistCheck = Boolean(newMultiplayerStatus.episodeId);
  //         if (isStreamExistCheck) {
  //           return;
  //         }
  //         setIsStreamExist(false);
  //         refreshFeaturedStreamView();
  //       }).catch(() => {
  //         setIsStreamExist(false);
  //         refreshFeaturedStreamView();
  //       });
  //     }, fetchTime);
  //   }
  //   return () => {
  //     if (fetchInterval) {
  //       clearInterval(fetchInterval);
  //     }
  //   };
  // }, [multiplayerId, isFeaturedView, refreshFeaturedStreamView]);

  useEffect(() => {
    document.body.classList.add(streamPageStyles.body);
    return () => {
      document.body.classList.remove(streamPageStyles.body);
    };
  }, [streamPageStyles.body]);

  const handleConnectedToRoom = useCallback((/* _data: ConnectedToRoomMessage */) => {
    // setConnectedToRoomData(data);
  }, []);

  const handleParticipants = useCallback((data: ParticipantsSocketMessage) => {
    const { participants } = data;
    const newHost = participants.find((participant) => participant.host);
    setHost(newHost);
  }, []);

  const handleSomeoneConnectedToRoom = useCallback((data: SomeoneConnectedToRoomSocketMessage) => {
    const { totalAppCount, totalWebCount } = data;

    const newTotalAppCount = totalAppCount != null ? totalAppCount : 0;
    const newTotalWebCount = totalWebCount != null ? totalWebCount : 0;
    setViewsCount(newTotalAppCount + newTotalWebCount);
  }, []);

  const handleSetNextEpisode = useCallback((data: SetNextEpisodeSocketMessage) => {
    const { episodeId: newEpisodeId } = data;
    setEpisodeId(newEpisodeId, 'ActiveStreampageV2 handleSetNextEpisode');
    // console.log('%c handleSetNextEpisode setEpisodeId', 'color: blue', newEpisodeId);
  }, [setEpisodeId]);

  const handleMultiplayerNewHost = useCallback((data: NewHostSocketMessage) => {
    const {
      playerId: newHostPlayerId,
    } = data;

    // Host left the stream
    if (!newHostPlayerId) {
      setEventTimeTitle('Stream Has Ended');
      setIsStreamExist(false);
      if (isFeaturedView && refreshFeaturedStreamView) {
        refreshFeaturedStreamView();
      }
    }
  }, [isFeaturedView, refreshFeaturedStreamView, setIsStreamExist]);

  useEffect(() => {
    multiplayerService.addListener(MultiplayerEvent.ConnectedToRoom, handleConnectedToRoom);
    multiplayerService.addListener(MultiplayerEvent.Participants, handleParticipants);
    multiplayerService.addListener(MultiplayerEvent.SomeoneConnectedToRoom, handleSomeoneConnectedToRoom);
    multiplayerService.addListener(MultiplayerEvent.SetNextEpisode, handleSetNextEpisode);
    multiplayerService.addListener(MultiplayerEvent.NewHost, handleMultiplayerNewHost);
    return () => {
      multiplayerService.removeListener(MultiplayerEvent.ConnectedToRoom, handleConnectedToRoom);
      multiplayerService.removeListener(MultiplayerEvent.Participants, handleParticipants);
      multiplayerService.removeListener(MultiplayerEvent.SomeoneConnectedToRoom, handleSomeoneConnectedToRoom);
      multiplayerService.removeListener(MultiplayerEvent.SetNextEpisode, handleSetNextEpisode);
      multiplayerService.removeListener(MultiplayerEvent.NewHost, handleMultiplayerNewHost);
    };
  }, [
    handleMultiplayerNewHost,
    handleParticipants,
    handleSetNextEpisode,
    handleSomeoneConnectedToRoom,
    handleConnectedToRoom,
  ]);

  useEffect(() => {
    let chatShowTimeout: ReturnType<typeof setTimeout> | null = null;
    if (episodeId) {
      chatShowTimeout = setTimeout(() => {
        // setIsChatVisible(true);
      }, 100);
    }

    return () => {
      if (chatShowTimeout) {
        clearTimeout(chatShowTimeout);
      }
    };
  }, [episodeId]);

  const { gameplayWidth, gameplayHeight } = getSizeConfig();

  const onOverlayClick = () => {
    // @ts-expect-error acessing window object
    window.top.location = window.self.location.toString();
  };

  const eventFinalTitle = eventTitle ?? episodeInfo?.title;
  // console.log('%c CURRENT EPISODE ID', 'color: purple', episodeId);
  // console.log('%c CURRENT MULTIPLAYER ID', 'color: purple', multiplayerId);
  return (
    <>
      <div className={streamPageStyles.getDorianApp}>
        <GetDorianAppSection />
      </div>
      <div className={streamPageStyles.streamContainerWithPaddings}>
        <div className={streamPageStyles.streamContainer} style={{ width: gameplayWidth, height: gameplayHeight }}>
          <MultiplayerProvider>
            <PopupDownloadProvider>
              <StreamV2
                gameplayWidth={gameplayWidth}
                gameplayHeight={gameplayHeight}
                episodeId={episodeId}
                setEpisodeId={setEpisodeId}
                multiplayerId={multiplayerId}
                eventTitle={eventFinalTitle}
                isFeaturedView={isFeaturedView}
                isStreamExist={isStreamExist}
              />

              {isStreamIframe && (
              <StreamOverlay
                onClick={onOverlayClick}
                title="Go to stream"
                sx={{ cursor: 'pointer' }}
              />
              )}
              { /* <SendbirdChat
                liveChatUrl={connectedToRoomData?.liveChatUrl}
                isChatVisible={isChatVisible}
                setIsChatVisbile={setIsChatVisible}
                setIsNewChatMessage={setIsNewChatMessage}
              /> */ }
            </PopupDownloadProvider>
          </MultiplayerProvider>
        </div>
      </div>
      {episodeInfo && host && (
      <>
        <div className={streamPageStyles.streamInfo}>
          <StreamInfoSectionV2
            eventTimeTitle={eventTimeTitle}
            eventTitle={eventTitle ?? episodeInfo.title}
            hostName={host?.username}
            hostAvatarUrl={host?.avatarUrl ?? ''}
            isShowMoreStreamsLink={!isStreamExist}
          />
        </div>
        <div className={streamPageStyles.streamInfoSmall}>
          <StreamInfoSectionV2Small
            eventTimeTitle={eventTimeTitle}
            eventTitle={eventTitle ?? episodeInfo.title}
            hostName={host?.username}
            hostAvatarUrl={host?.avatarUrl ?? ''}
            viewsCount={viewsCount}
            isShowMoreStreamsLink={!isStreamExist}
          />
        </div>
      </>
      )}
    </>
  );
}
